import { Component } from '@angular/core';
import { Input, Output, ContentChild, TemplateRef, ElementRef, EventEmitter } from '@angular/core';
import { BaseApiObject } from '../../../../model/base-api-object';
import { ApiCollection } from '../../../../model/api-coillection.model';
import { BehaviorSubject } from 'rxjs';

export interface SelectedOptions {
  [ key: string ]: Boolean 
}

@Component({
  selector: 'app-select-from-available',
  templateUrl: './select-from-available.component.html',
  styleUrl: './select-from-available.component.css'
})
export class SelectFromAvailableComponent<ApiObject extends BaseApiObject, ApiObjectAvailable extends BaseApiObject> {

  @Input() groupCollection$!: BehaviorSubject<ApiCollection<ApiObject>>
  @Input() availableCollection$!: BehaviorSubject<ApiCollection<ApiObjectAvailable>>
  @ContentChild('memberRowT') memberRowTemplate!: TemplateRef<any>;
  @ContentChild('availableRowT') availableRowTemplate!: TemplateRef<any>;
  @Input() canEdit?:  Boolean; 

  @Output() addToGroup       = new EventEmitter<{ [ key: string ]: any }>();
  @Output() removeFromGroup  = new EventEmitter<{ [ key: string ]: any }>();
  @Output() newGroupPage     = new EventEmitter<number>();
  @Output() newAvailablePage = new EventEmitter<number>();

  private selectedFromAvailable: SelectedOptions = {};
  private selectedFromGroup: SelectedOptions = {};

  toggleAvailableSelect( object: ApiObjectAvailable ) {
                              
    if ( this.selectedFromAvailable[object.id] ) {
        this.selectedFromAvailable[object.id] = false
    }
    else {
        this.selectedFromAvailable[object.id] = true
    }
  }

  isAvailableSelected( object: ApiObjectAvailable) {
     return this.selectedFromAvailable[object.id]
  }

  toggleFromGroupSelect(object: ApiObject) {

    if ( this.selectedFromGroup[object.id] ) {
        this.selectedFromGroup[object.id] = false
    }
    else {
        this.selectedFromGroup[object.id] = true
    }
  }

  isFromGroupSelected( object: ApiObject) {
     return this.selectedFromGroup[object.id]
  }

  doAddToGroup() {
    const selected = this.selectedFromAvailable;
    this.selectedFromAvailable = {};

    this.addToGroup.emit(selected);

  }

  doRemoveFromGroup() {
    const selected = this.selectedFromGroup;
    this.selectedFromGroup = {};

    this.removeFromGroup.emit(selected);

  }

  loadGroupPage( page: number ) {
     this.newGroupPage.emit(page);

  }

  loadAvailablePage( page: number ) {
     this.newAvailablePage.emit(page);
  }
}
