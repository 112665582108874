import { Component, Host, Input } from '@angular/core';
import { CommandInstanceService } from '../../services/command-instance.service';
import { HostJobService } from '../../services/host-job.service';
import { HostJob } from '../../models/host-job.model';
import { BehaviorSubject } from 'rxjs';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { HostJobLog } from '../../models/host-job-log.model';

@Component({
  selector: 'app-host-job-log',
  templateUrl: './host-job-log.component.html',
  styleUrl: './host-job-log.component.css'
})
export class HostJobLogComponent {

  @Input() hostJob!: HostJob;

  public hostJobLogs$!: BehaviorSubject<ApiCollection<HostJobLog>>

  constructor(
    private CommandInstanceService: CommandInstanceService, 
    private HostJobService: HostJobService, 
  ) {}

  ngOnInit(){

    const cfg = { searchAttrs: { limit: 50 } };

    this.HostJobService.getJobLogs( this.hostJob, cfg )
        .subscribe( hostJobLogs => { 

            this.hostJobLogs$ = new BehaviorSubject<ApiCollection<HostJobLog>>(hostJobLogs);  
        })

      
  }

  levelClass(hostJobLog: HostJobLog) {
    let level = hostJobLog.level;

    if ( level === 'stdout' ) {
      return 'text-bg-secondary';
    }
    else if ( level === 'stderr') {
      return 'text-bg-warning'
    }
    else if ( level === 'error') {
      return 'text-bg-danger'
    }
    else if ( level === 'warning') {
      return 'text-bg-warning'
    }

    else {
      return ''
    }
  }

}
