<div *ngIf="(users$ | async) as users; else loadingBlock" class="d-flex flex-column h-100">
  <div class="row px-4">
    <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th class="col-5 text-center">Email</th>
            <th class="col-4 text-center">Role</th>
            <th class="col-4 text-center">Status</th>
          </tr>   
        </thead>
        <tbody>
             <tr *ngFor="let user of users.collection | callbackFilter:filterActiveAccount(userMap)"
                 (click)="loadUserDetail(user)"
             >
               <td class="col-5 text-center">{{ userMap[user.account_user].email_addr }}</td>
               <td class="col-4 text-center">{{ userMap[user.account_user].role }}</td>
               <td class="col-4 text-center">{{ userMap[user.account_user].status }}</td>
             </tr>   
        </tbody>
    </table>
  </div>
  <div *ngIf="(users$ | async) as users" class="row mt-auto mx-auto">
    <app-pager [collection]="users" (newPage)="loadPage($event)"></app-pager>
  </div>

</div>
  <ng-template #loadingBlock>
    <app-tab-loading></app-tab-loading>
  </ng-template>
  <ng-template #waitRegBlock>
     <td>Awaiting Registration</td>
  </ng-template>



