import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, first, Subject, Subscription, debounceTime } from 'rxjs';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Command } from '../../models/command.model';
import { CommandInstance, CommandInstanceStub } from '../../models/command-instance.model';
import { CommandInstanceService } from '../../services/command-instance.service';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { CommandInstanceLog } from '../../models/command-instance-log.model';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';

@Component({
  selector: 'app-instance-config-tab',
  templateUrl: './instance-config-tab.component.html',
  styleUrl: './instance-config-tab.component.css'
})
export class InstanceConfigTabComponent {

  public instanceConfigForm!: FormGroup; 
  public logs$!: BehaviorSubject<ApiCollection<CommandInstanceLog>>

  public instance$: BehaviorSubject<CommandInstance> = new BehaviorSubject<CommandInstance>(new CommandInstanceStub);  
  //public instance$!: Subject<CommandInstance>; //= new Subject<CommandInstance>();  

  private subscription: Subscription = new Subscription();
  private commands: Subject<string> = new Subject();

  public header!: string;

  constructor(
    private CommandInstanceService: CommandInstanceService, 
    private route: ActivatedRoute,
    private router: Router,
    private SiteError: SiteErrorService,
    private LoadingModal: LoadingModalService
  ) {

      //this.instance$ = new Subject<CommandInstance>();  

      this.instanceConfigForm = new FormBuilder().group({
                                      label:            [ '', [ Validators.required, ] ], 
                                      status:           [ '', [ Validators.required, ] ], 
                                      command_priority: [ '', [ Validators.required, ] ], 
                                      max_retry:        [ '', [ Validators.required, ] ], 
                                      retry_delay:      [ '', [ Validators.required, ] ], 
                                      max_executing:    [ '', [ Validators.required, ] ], 
                                    }); 
      // TODO remove all this since we have the deBounce directive
      this.subscription.add( 
          this.commands.subscribe(( command: string ) => {

             this.LoadingModal.setLoading();

             let currentInstance = this.CommandInstanceService.currentInstance;

             if ( command === 'start' ) {
                this.doStartInstance(currentInstance);
             }
             else if ( command === 'pause' ) {
                this.doPauseInstance(currentInstance);
             }
             else if ( command === 'unpause' ) {
                this.doUnPauseInstance(currentInstance);
             }

          })
      )


  }


  ngOnInit(){

    this.route.params
              .pipe(
                filter( params => params['instance_id'] ),
                switchMap((params) => { 
                  return this.CommandInstanceService.getCurrentInstance(params['instance_id'])  
                }),
                first()
              )
              .subscribe( instance => {

                console.log('inst init');
                console.log(instance);
                this.instanceConfigForm.setValue({
                      label: instance.label,
                      status: instance.status,
                      command_priority:  instance.getAttribute('command_priority'),
                      max_retry: instance.getAttribute('max_retry'),
                      retry_delay: instance.getAttribute('retry_delay'),
                      max_executing: instance.getAttribute('max_queuing'),
                    });

                //this.instance$ = new BehaviorSubject<CommandInstance>(new CommandInstanceStub);  
                this.header = instance.command_string;

                if ( instance.executeType == 'script') {
                  this.header =  '$'+ instance.command.name.toUpperCase() +' '+ this.header
                }

                this.instance$.next(instance);
              });

    this.getLogs({ searchAttrs: { order_by: { desc: 'date_time' }, limit: 5 } })
        .pipe( first() ) 
        .subscribe((logs) => {
           this.logs$ = new BehaviorSubject<ApiCollection<CommandInstanceLog>>(logs);  
        }) 

  }


  get cf(): FormGroup { return this.instanceConfigForm; }
 
  onRetryChange() {
   
     if (!this.cf.get('max_retry')?.value) {
         this.cf.get('retry_delay')?.disable()
     }
  }

  get retryDisabled() {
    return !this.cf.get('max_retry')?.value ;
  }

  updateInstanceConfig() {

    if (this.cf.invalid) {

      console.log(this.cf.controls);
      
      return;
    }

    let formVals        = this.cf.value; 
    let currentInstance = this.CommandInstanceService.currentInstance;
    let updateInstance  = new CommandInstance(currentInstance);

    updateInstance.setAttribute( 'command_priority', formVals.command_priority );
    updateInstance.setAttribute( 'max_retry', formVals.max_retry );
    updateInstance.setAttribute( 'retry_delay', formVals.retry_delay );
    updateInstance.setAttribute( 'max_queuing', formVals.max_executing );

    this.CommandInstanceService
        .updateCurrentInstance(updateInstance)
        .pipe(first())
        .subscribe({
          next: (instance) => {
               this.instance$.next(instance);
          },
          error: (resp) => {
              console.log('error')
          }
        });

  }

  startInstance() {
     this.commands.next('start')
  }

  doStartInstance( currentInstance: CommandInstance) {

      this.CommandInstanceService
          .startInstance(currentInstance)
          .subscribe({
             next: (instance) => {
                 // get instance response, update currentInstance with service, $instance.next
                 // updateInstance() method
                this.updateInstance(instance);
                this.LoadingModal.unsetLoading();
             },
             error: (error) => {
                this.LoadingModal.unsetLoading();
                this.SiteError.setApiError(error);
             }

          })
  }
 
  private updateInstance(instance: CommandInstance) {

     this.CommandInstanceService.setCurrentInstance(instance);
     this.instance$.next(instance);

     this.getLogs({ searchAttrs: { order_by: { desc: 'date_time' }, limit: 5 } })
        .pipe( first() ) 
        .subscribe( (logs) => {
           this.logs$.next(logs);  
        }) 

  }

  pauseInstance() {
     this.commands.next('pause')
  }

  doPauseInstance( currentInstance: CommandInstance) {

      this.CommandInstanceService
          .pauseInstance(currentInstance)
          .subscribe({
             next: (instance) => {
                this.updateInstance(instance);
                this.LoadingModal.unsetLoading();

             },
             error: (error) => {
                this.LoadingModal.unsetLoading();
                this.SiteError.setApiError(error);
             }

          })
  }

  unpauseInstance() {
     this.commands.next('unpause')
  }

  doUnPauseInstance( currentInstance: CommandInstance) {

      this.CommandInstanceService
          .unPauseInstance(currentInstance)
          .subscribe({
             next: (instance) => {
                this.updateInstance(instance);
                this.LoadingModal.unsetLoading();
             },
             error: (error) => {
                this.LoadingModal.unsetLoading();
                this.SiteError.setApiError(error);
             }

          })
  }

  completeInstance() {

      this.LoadingModal.setLoading();

      let currentInstance = this.CommandInstanceService.currentInstance;

      this.CommandInstanceService
          .completeInstance(currentInstance)
          .subscribe({
             next: (instance) => {
                this.updateInstance(instance);
                this.LoadingModal.unsetLoading();
             },
             error: (error) => {
                this.LoadingModal.unsetLoading();
                this.SiteError.setApiError(error);
             }
          })
  }

  cancelInstance() {

      this.LoadingModal.setLoading();

      let currentInstance = this.CommandInstanceService.currentInstance;

      this.CommandInstanceService
          .cancelInstance(currentInstance)
          .subscribe({
             next: (instance) => {
                this.updateInstance(instance);
                this.LoadingModal.unsetLoading();
             },
             error: (error) => {
                this.LoadingModal.unsetLoading();
                this.SiteError.setApiError(error);
             }
          })
  }

  getLogs( cfg?: { [name: string]: any } ) {
    return this.instance$
               .pipe( 
                  filter( instance => !instance.is_stub ),
                  switchMap((instance) => {
                    return this.CommandInstanceService.getLogs( instance, cfg );
                  })
               )
  }


  //ngOnDestroy(): void {
  //   this.subscription.unsubscribe();
  //}
}


