import { Router, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { CurrentUserService } from '../shared/services/current-user.service';

export const loggedInGuard: CanActivateFn = (route, state) => {
  const currentUserService: CurrentUserService = inject(CurrentUserService);
  const router: Router = inject(Router);

  if ( currentUserService.logged_in === false ) {
      return router.createUrlTree(['/login']);
  }

  return true;
};
