<div class="row ps-0 justify-content-center"  style="margin: auto">
    <div  class="px-5" >

      <div class="col pt-5">
        <div class="row d-flex" style="margin: auto;">
          <div class="col-5 flex-column flex-grow-1 text-center px-4">
             <ng-content select="[members-header]"></ng-content>
          </div>
          <div class="col-1 flex-column flex-grow-1 ">
          </div>
          <div class="col-5 flex-column flex-grow-1 text-center">
             <div *ngIf="canEdit" >
                <ng-content select="[available-header]"></ng-content>
             </div>
          </div>
        </div>
        <div class="row d-flex" style="min-height: 30em; margin: auto;">
          <div class="col-5 flex-column flex-grow-1 px-2">
            <div class="row" style="margin: auto;">
              <table class="table table-hover mb-0">
                  <thead class="table-dark">
                    <ng-content select="[members-table-header]"></ng-content>
                  </thead>
              </table>
              <div class="overflow-auto px-0" style="height: 80% !important">
                <table class="table table-hover mb-0">
                   <tbody *ngIf="(groupCollection$ | async) as groupCollection" >

                     <tr *ngFor="let member of groupCollection.collection"
                         [class.table-primary]="isFromGroupSelected(member)"
                         (click)="toggleFromGroupSelect(member)" 
                     >

                       <ng-template 
                           ngFor let-member [ngForOf]="[member]" [ngForTemplate]="memberRowTemplate"
                       ></ng-template>

                       <!--
                       <ng-container 
                          *ngTemplateOutlet="memberRowTemplate; context: { member: [member] }">
                       </ng-container>
                       <ng-template                           
                           [ngTemplateOutlet]="memberRowTemplate"
                           [ngTemplateOutletContext]="{ member: [member] }"
                       >
                       </ng-template>
                       -->
                     </tr>   
                   </tbody>
                </table>
              </div>
            </div>
            <div class="row flex-grow-1">
            </div>
          </div>
      
          <div class="col-1 px-0 flex-column flex-grow-1">
            <div *ngIf="canEdit"  class="row d-flex h-100" style="margin: auto;">
               <div class="col-12 d-flex justify-content-center my-auto">
                 <button addDeBounce 
                         class="btn btn-primary" 
                         (deBounceClick)="doAddToGroup()"
                 >
                   <i class="bi bi-box-arrow-in-left h2"></i>
                 </button>
               </div>
               <div class="col-12  d-flex justify-content-center my-auto">
                 <button addDeBounce 
                         class="btn btn-danger" 
                         (deBounceClick)="doRemoveFromGroup()"
                 >
                   <i class="bi bi-box-arrow-in-right h2"></i> 
                 </button>
               </div>
               <div class="col-12  d-flex justify-content-center my-auto">
               </div>
            </div>
          </div>
      
          <div class="col-5 flex-column flex-grow-1 px-2">
            <div *ngIf="canEdit" class="row" style="margin: auto;">
              <table class="table table-hover mb-0">
                  <thead class="table-dark">
                    <ng-content select="[available-table-header]"></ng-content>
                  </thead>
              </table>
              <div class="overflow-auto px-0" style="height: 80% !important">
                <table class="table table-hover mb-0">
                   <tbody *ngIf="(availableCollection$ | async) as availableCollection" >
                     <tr *ngFor="let available of availableCollection.collection"
                         [class.table-primary]="isAvailableSelected(available)"
                         (click)="toggleAvailableSelect(available)"
                     >
                       <ng-template 
                           ngFor let-available [ngForOf]="[available]" [ngForTemplate]="availableRowTemplate"
                       ></ng-template>
                     </tr>   
                   </tbody>
                </table>
              </div>
            </div>
      
          </div>
      
        </div>
        <div class="row" style="margin: auto;">
          <div class="col-5 flex-column flex-grow-1">
      
            <div *ngIf="(groupCollection$ | async) as group" 
                  class="row justify-content-center"
                  style="margin: auto;"
            >
              <app-pager [collection]="group" (newPage)="loadGroupPage($event)"></app-pager>
            </div>
      
      
          </div>
          <div class="col-1 flex-column flex-grow-1 ">
          </div>
          <div class="col-5 flex-column flex-grow-1">
          
            <div *ngIf="( availableCollection$ | async) as available" 
                  class="row justify-content-center"
                  style="margin: auto;"
            >
              <app-pager *ngIf="canEdit" [collection]="available" (newPage)="loadAvailablePage($event)"></app-pager>
            </div>
      
          </div>
        </div>
      
      </div>

    </div>

</div>