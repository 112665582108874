<div *ngIf="(currentCommand$ | async) as command" >
  <form  [formGroup]="InstanceConfigForm">
    <div class="row mt-4 mb-4">
       <div class="col-6">
       </div>
       <div class="col-4">
          <button *ngIf="command.hasPermission('execute')"
                  addDeBounce 
                  (deBounceClick)="doAddInstance()" 
                  class="btn btn-primary"
          >
            Create
          </button>
       </div>
    </div>
    <div class="col-10 ms-5 pt-4" > 
      <div class="mb-3 row">
        <label for="name" class="form-label fw-bold col-2">Label</label>
        <div class="col-6">
            <input type="text" 
                 class="form-control" 
                 id="label" 
                 aria-describedby="label" 
                 formControlName="label">
        </div>
      </div>
      <div *ngIf="command.has_parameters()">
        <div *ngFor="let param of command.parameters" class="mb-3 row">
          <label [for]="param.name" class="form-label fw-bold col-2">{{ param.name }}</label>
          <div *ngIf="param.type == 'free_form'" class="col-6">
            <input type="text" 
                 class="form-control" 
                 [id]="param.name" 
                 [attr.aria-describedby]="param.name" 
                 [formControlName]="param.name"
                 [value]="param.default">

            <div *ngIf="getFormControlError( InstanceConfigForm, param.name, submitted ); let errors"> 
              <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
                {{ error.value }} 
              </div>
            </div>

          </div>
          <div *ngIf="param.type == 'select'" class="col-6">
            <select class="form-select" [formControlName]="param.name" id="param.name" >
                <option value="" disabled selected hidden>Select Option...</option>
                <option *ngFor="let option of param.opts.options" [value]="option">
                   {{ option }} 
                </option>
            </select>
            <div *ngIf="getFormControlError( InstanceConfigForm, param.name, submitted ); let errors"> 
              <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
                {{ error.value }} 
              </div>
            </div>
          </div>
          <div *ngIf="param.type == 'range'" class="col-6">
            <input type="number" 
                   class="form-control text-end" 
                   id="param.name" 
                   aria-describedby="param.name" 
                   [formControlName]="param.name">

            <div *ngIf="getFormControlError( InstanceConfigForm, param.name, submitted ); let errors"> 
              <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
                {{ error.value }} 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

