<div *ngIf="(command$ | async) as command; else loadingBlock" class="row  h-100">
    <app-command-config 
       [formAction]="formAction" 
       [command]="command"
       (newCommandEvent)="updateCommand($event)">

      <div *ngIf="command.has_parameters()" command>
        <div class="mb-3 row">
          <h2> Parameterss</h2> 
          <div *ngFor="let param of command.parameters"  class="row" >
             <label class="ps-3 fw-bold col-2">{{ param.name }}</label>
             <label class="ps-3 fw-bold col-2">Type: </label>
             <div class="col-2">
             <select class="form-select" (ngModel)="param.type" id="param.name +'_type'" >
               <option value="free_form">
                 Free 
               </option>
               <option value="select">
                 Select 
               </option>
               <option value="range">
                 Range
               </option>
             </select>
             </div>
             <label *ngIf="param.default" class="ps-3 fw-bold col-4"> [{{ param.default }}]</label>
          </div>
        </div>
      </div>

      <div *ngIf="command.has_parameters()" script>
        <div class="mb-3 row">
          <label for="name" class="form-label fw-bold col-6">Parameters     [default]</label>
          <div *ngFor="let param of command.parameters" >
             <label class="ps-3 fw-bold col-2">{{ param.name }}</label>
             <label *ngIf="param.default" class="ps-3 fw-bold col-4"> [{{ param.default }}]</label>
          </div>
        </div>
      </div>


    </app-command-config>
</div>
<ng-template #loadingBlock>
  <div class="row flex-grow-1 h-100">
    <app-tab-loading></app-tab-loading>
  </div>
</ng-template>
