<div class="container d-flex flex-column h-100">
  <div class="row px-4">
    <div class="col-8 ps-4 pt-4">
      <div *ngIf="(commands$ | async) as commands">
        <a *ngIf="activeUser.hasSitePermission('create_commands')"
           [routerLink]="['create']">
           <button class="btn btn-primary">
              <i class="bi bi-plus-square-fill me-1"></i> Add Command
           </button>
        </a> 
      </div>
    </div>
    <div class="col-4  px-4 pt-4">
      <!--
       <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search...">
       -->
    </div>
  </div>
  <div *ngIf="(commands$ | async) as commands; else loadingBlock" class="row p-4">
    <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th class="col-3 text-center">Name</th>
            <th class="col-5 text-center">Description</th>
            <th class="col-4 text-center">Creator</th>
          </tr>   
        </thead>
        <tbody >
             <tr *ngFor="let command of commands.collection; index as i" (click)="loadCommandDetail(command)">
               <td class="text-center">{{ command.name }}</td>
               <td class="text-center">{{ command.description }}</td>
               <td class="text-center">
                {{ userMap[ userIDMap[command.creator].account_user ].email_addr }}
               </td>
             </tr>   
        </tbody>
    </table>
  </div>
  <div *ngIf="(commands$ | async) as commands" class="row mt-auto mx-auto">
    <app-pager [collection]="commands" (newPage)="loadPage($event)"></app-pager>
  </div>
  <ng-template #loadingBlock>
    <app-tab-loading></app-tab-loading>
  </ng-template>
  <ng-template #waitRegBlock>
     <td>Awaiting Registration</td>
  </ng-template>

</div>
