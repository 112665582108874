import { objectAttributes, objectAttribute, objectAttributeValue } from "../../../shared/interfaces/object-attributes";

type Constructor = new (...args: any[]) => {};
 
export function WithAttributes<TBase extends Constructor>(Base: TBase) {
  return class AddAttributes extends Base {

    public attributes!: objectAttributes;

    public writableAttributes: Array<string> = [];

    public getAttribute(attribute: string): objectAttributeValue | null { // string | number | Array<any> | { [key: string]: any} |  null  { 

        if ( this.attributes[attribute] ) {
            return this.attributes[attribute].value;
        }

        return null
    } 

    public setAttribute(attribute: string, value: objectAttributeValue, create?: Boolean): objectAttributeValue | null { // string | number | Array<any> | { [key: string]: any} |  null  { 

        if ( this.attributes[attribute] ) {
            return this.attributes[attribute]['value'] = value;
        }
        if ( create ) {
           this.attributes[attribute] = { 'value': value };
        }

        return null
    } 

    public updateAttributes(): { [ name: string ]: any }{

      let ret : { [ name: string ]: any } = {};

      this.writableAttributes.forEach((attr: string) => { 
         ret[attr] = this.getAttribute(attr) 
      });

      return { 'attributes': ret };
    }


  };
}
