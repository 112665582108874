import { Component } from '@angular/core';
import { HostService } from '../../services/host.service';
import { SiteService } from '../../services/site.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { Host, HostStub } from '../../models/host.model'; 
import { first, tap, filter, switchMap, subscribeOn } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { HostLog } from '../../models/host-log.model';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import moment from 'moment';
import { LoggerService } from '../../../../shared/services/logger.service';

@Component({
  selector: 'app-system-tab',
  templateUrl: './system-tab.component.html',
  styleUrl: './system-tab.component.css'
})
export class SystemTabComponent {

  public host$: BehaviorSubject<Host> = new BehaviorSubject<Host>(new HostStub);  
  public logs$!: BehaviorSubject<ApiCollection<HostLog>>

  public systemConfigForm: FormGroup  = new FormBuilder().group({
                                              hostname: ['', [Validators.required ] ],
                                              uriKey: ['', [Validators.required ]],
                                              status: ['', [Validators.required ]],
                                              notes: ['', []]
                                            });

  public activationTokenVisible: boolean = false;
  public activationToken: string = 'Loading....' 

  constructor( 
    private HostService: HostService, 
    private route: ActivatedRoute,
    private siteService: SiteService,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService,
    private router: Router,
    private logger: LoggerService
  ) {}

  ngOnInit(){

    this.route.params
              .pipe(
                filter( params => params['host_id'] ),
                switchMap((params) => { 
                  return this.HostService.getCurrentHost(params['host_id'])  
                })
              )
              .subscribe({
                next: host => {
               
                   this.systemConfigForm.setValue({ 
                                           hostname: host.hostname, 
                                           uriKey:   host.uriKey, 
                                           status:   host.status,
                                           notes:    host.notes
                                         });

                   if ( host.status === 'wait_reg' ) {
                      this.systemConfigForm.get('status')?.disable();
                   }

                    this.host$.next(host);
                },
                error: error => {
                  this.logger.error(error)
                  this.router.navigate([ '../../' ], { relativeTo: this.route });

                }
              });

    // order_by => { asc => 'created' }

    this.getLogs({ searchAttrs: { order_by: { desc: 'date_time' }, limit: 5 } })
        .subscribe((logs) => {
           this.logs$ = new BehaviorSubject<ApiCollection<HostLog>>(logs);  
        }) 

  }

  showActivationToken() {

      let host = this.HostService.currentHost;
      this.HostService.getActivationToken(host)
                      .subscribe({
                           next: (tokenData) => {
                              console.log(tokenData)
                              this.activationToken = tokenData['activation_token'];
                              this.activationTokenVisible = true;
                           },
                           error: () => {}
                      });
  }

  get sf(): FormGroup { return this.systemConfigForm; }

  updateSystemConfig() {

    if (this.sf.invalid) {

      // TODO generalize into some error reporting service
      const result: any[] = [];
      Object.keys(this.sf.controls).forEach(key => {
    
        const controlErrors = this.sf.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': key,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      });

      return;
    }

    this.LoadingModal.setLoading();

    let formVals    = this.sf.value; 
    let currentHost = this.HostService.currentHost;
    let updateHost  = new Host({ 
                            id: currentHost.id, 
                            hostname: formVals.hostname, 
                            status: formVals.status,
                            notes: formVals.notes,
                            attributes: { 'uri_key': { 'value': formVals.uriKey } }
                          }); 

    //console.log(updateHost.updateData());
    //console.log(this.HostService.currentHost.updateData());

    this.HostService
        .updateCurrentHost(updateHost)
        .subscribe({
          next: (host) => {
               //console.log('success');
               this.host$.next(host);
               this.LoadingModal.unsetLoading();

          },
          error: (error) => {
              this.LoadingModal.unsetLoading();
              this.SiteError.setApiError(error);
          }
        });

  }

  // order_by => { asc => 'created' }
  getLogs( cfg?: { [name: string]: any } ) {
    return this.host$
               .pipe( 
                  filter( host => !host.is_stub ),
                  switchMap((host) => {
                    return this.HostService.getHostLogs( host, cfg );
                  })
               )
  }

}
