import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiCollection } from '../../../../model/api-coillection.model';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrl: './pager.component.css'
})
export class PagerComponent {

  @Input() collection!: ApiCollection<any>;
  @Output() newPage = new EventEmitter<number>();


  public collection$!: BehaviorSubject<ApiCollection<any>>
  public pages!:  Array<{ page_num: number, cur_page: boolean}>;


  ngOnInit(){

     this.pages = [ ...Array(this.collection.last_page)
                         .keys()]
                         .map( (i) => { 
                             return { 
                               page_num: i + 1,
                               cur_page: ( i + 1 == this.collection.current_page) 
                                           ? true 
                                           : false
                             }

                         })

     this.collection$ = new BehaviorSubject<ApiCollection<any>>(this.collection);
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes['collection']) {

      const collection = changes['collection'].currentValue;

      this.pages = [ ...Array(collection.last_page)
                          .keys()]
                          .map( (i) => { 
                              return { 
                                page_num: i + 1,
                                cur_page: ( i + 1 == collection.current_page) 
                                            ? true 
                                            : false
                              }

                          })

      this.collection$?.next(changes['collection'].currentValue);
    }
 
  }

  goToPage(page: number) {
    if ( page == this.collection.current_page ) {
       return
    }
    this.newPage.emit(page);
  }

}
