import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingModalService } from '../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteErrorService, SiteError } from '../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { AccountUsersService } from '../../services/account-users.service';


@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrl: './user-add.component.css'
})
export class UserAddComponent {

  public newUserForm!: FormGroup; 

  constructor( 
    private router: Router,
    private route:  ActivatedRoute,
    private UserService: AccountUsersService,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService
  ) {
       this.newUserForm = new FormBuilder().group({
                                              email: [ '', 
                                                          [ Validators.required, 
                                                            Validators.email
                                                            // this.HostService.hostnameValidator 
                                                          ] 
                                                        ],
                                              role: ['Service', [ Validators.required] ]
                                            });

      //this.newUserForm.setValue({ role: 'Service' });
  }

  get nf(): FormGroup { return this.newUserForm; }

  inviteUser() {
    console.log('here')
    if (this.nf.invalid) {

      console.log(this.nf.controls);
      
      return;
    }
    
    this.LoadingModal.setLoading();

    let formVals    = this.nf.value; 

    this.UserService
        .inviteUser( formVals.email, formVals.role )
        .subscribe({
          next: (userRole) => {
            this.LoadingModal.unsetLoading();
            this.router.navigate([ '../'+ userRole.uriKey ], { relativeTo: this.route });
          },
          error: (error) => {
              this.LoadingModal.unsetLoading();
              this.SiteError.setApiError(error);
          }
        });



  }

}
