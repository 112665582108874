<div class="container ps-0 ms-0 h-100">
                                                 <!-- fixes row breaking out of div-->
  <div class="row ps-0 justify-content-center"  style="margin: auto">

    <div class="my-4 ps-0">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item px-2">
            <a class="nav-link active text-dark bg-white" 
                id="setting-tab" 
            >
            Settings 
            </a>
          </li>
      </ul>
    </div>

    <div *ngIf="(group$ | async) as group" class="px-3" >
      <div class="col">
        <form  [formGroup]="groupConfigForm" (ngSubmit)="updateGroupConfig()">
          <fieldset [disabled]="group.readOnly">
            <div class="row">
               <div class="col-6">
                 <h5 class="mb-0 mt-2">Info</h5> 
               </div>
               <div class="col-6" *ngIf="!group.readOnly">
                  <button type="submit" class="btn btn-primary">Update</button>
               </div>
            </div>
            <div class="col-10 px-3 py-4" > 
              <div class="mb-3 row">
                <label for="name" class="form-label fw-bold col-2">Name</label>
                <div class="col-5">
                    <input type="text" 
                         class="form-control col-10" 
                         id="name" 
                         aria-describedby="name" 
                         formControlName="name">
                </div>
              </div>
              <div class="mb-3 row">
                <label for="description" class="form-label fw-bold col-2">Description</label>
                <div class="col-5">
                  <input type="text" 
                         class="form-control" 
                         id="description" 
                         formControlName="description"> 
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <hr />
      <div class="col pt-3">
        <div class="row d-flex" style="margin: auto;">
          <div class="col-5 flex-column flex-grow-1 text-center px-4">
             <h4>Members</h4>
          </div>
          <div class="col-1 flex-column flex-grow-1 ">
          </div>
          <div class="col-5 flex-column flex-grow-1 text-center">
             <h4 *ngIf="!group.readOnlyMembers && group.hasPermission('edit')">Available Users</h4>
          </div>
        </div>
        <div class="row d-flex" style="min-height: 30em; margin: auto;">
          <div class="col-5 flex-column flex-grow-1 px-4">
            <div class="row" style="margin: auto;">
              <table class="table table-hover mb-0">
                  <thead class="table-dark">
                    <tr>
                      <th class="col-7 text-center">Username</th>
                      <th class="col-5 text-center">Status</th>
                    </tr>   
                  </thead>
              </table>
              <div class="overflow-auto px-0" style="height: 80% !important">
                <table class="table table-hover mb-0">
                   <tbody *ngIf="(members$ | async) as members" >
                     <tr *ngFor="let member of members.collection"
                         [class.table-primary]="isGroupUserSelected(member)"
                         (click)="toggleGroupUserSelect(member)"

                     >
                       <td class="col-7 text-center">{{ userMap[member.account_user].email_addr }}</td>
                       <td class="col-5 text-center">{{ userMap[member.account_user].status}}</td>
                     </tr>   
                   </tbody>
                </table>
              </div>
            </div>
            <div class="row flex-grow-1">
            </div>
          </div>

          <div class="col-1  flex-column flex-grow-1">
            <div *ngIf="!group.readOnlyMembers && group.hasPermission('edit')" class="row d-flex h-100">
               <div class="col-12 d-flex justify-content-center my-auto">
                 <button addDeBounce 
                         class="btn btn-primary" 
                         (deBounceClick)="doAddMember()"
                 >
                   <i class="bi bi-box-arrow-in-left h2"></i>
                 </button>
               </div>
               <div class="col-12  d-flex justify-content-center my-auto">
                 <button addDeBounce 
                         class="btn btn-danger" 
                         (deBounceClick)="doRemoveMember()"
                 >
                   <i class="bi bi-box-arrow-in-right h2"></i> 
                 </button>
               </div>
            </div>
          </div>

          <div class="col-5 flex-column flex-grow-1 px-2">
            <div *ngIf="!group.readOnlyMembers && group.hasPermission('edit')" class="row" style="margin: auto;">
              <table class="table table-hover mb-0">
                  <thead class="table-dark">
                    <tr>
                      <th class="col-7 text-center">Username</th>
                      <th class="col-5 text-center">Status</th>
                    </tr>   
                  </thead>
              </table>
              <div class="overflow-auto px-0" style="height: 80% !important">
                <table class="table table-hover mb-0">
                   <tbody *ngIf="(available$ | async) as available" >
                     <tr *ngFor="let user of available.collection"
                         [class.table-primary]="isAvailableSelected(user)"
                         (click)="toggleAvailableSelect(user)"
                     >
                       <td class="col-7 text-center">{{ userMap[user.account_user].email_addr }}</td>
                       <td class="col-5 text-center">{{ userMap[user.account_user].status}}</td>
                     </tr>   
                   </tbody>
                </table>
              </div>
            </div>

          </div>

        </div>
        <div class="row" style="margin: auto;">
          <div class="col-5 flex-column flex-grow-1">

            <div *ngIf="(members$ | async) as members" 
                  class="row justify-content-center"
                  style="margin: auto;"
            >
              <app-pager [collection]="members" (newPage)="loadMembersPage($event)"></app-pager>
            </div>


          </div>
          <div class="col-1 flex-column flex-grow-1 ">
          </div>
          <div class="col-5 flex-column flex-grow-1">
          
            <div *ngIf="( available$ | async) as available" 
                  class="row justify-content-center"
                  style="margin: auto;"
            >
              <app-pager *ngIf="!group.readOnlyMembers && group.hasPermission('edit')" [collection]="available" (newPage)="loadAvailablePage($event)"></app-pager>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
