import { Injectable } from '@angular/core';
import { AccessApiService, ExcClientRequestCfg } from '../../../shared/services/access-api.service';
import { SiteService } from './site.service';
import { Site } from '../models/site.model';
import { SiteUser } from '../models/site-user.model';
import { sprintf } from 'sprintf-js';
import { switchMap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteUsersService {

  constructor(
    private accessApi: AccessApiService,
    private siteService: SiteService,
  ) { }

  public getUsers( cfg?: ExcClientRequestCfg )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getUserCollectionRequest(site, cfg ) })
                );
  }

  public getUser( userUriKey: string )  {
     
     let cfg = { searchArgs: { attributes: { uri_key: userUriKey } } };

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getUserCollectionRequest(site, cfg) }),
                  map( (collection) => { 
                    const user = collection.first();

                    if ( !user ) {
                      throw new Error(`User ${ userUriKey } not found`); 
                    }
 
                    return user 
                  })
                );
  }

  private getUserCollectionRequest( site:  Site, cfg?: ExcClientRequestCfg ) {

      return this.accessApi.getCollection(SiteUser, sprintf('/excollect_api/sites/%s/users', site.id), cfg)
  }

  public updateUser( user: SiteUser )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.updateUserCollectionRequest(site, [ user.updateData() ] ) } ),
                  map( (collection) => { return collection.first() })
                );
  }

  public updateUserCollectionRequest( site:  Site, data: Array<Object> ) {
    
      return this.accessApi.updateCollection(SiteUser, sprintf('/excollect_api/sites/%s/users', site.id), data )
  }

}
