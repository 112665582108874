import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RwUtilsModule } from '../shared/modules/rw-utils/rw-utils.module';

import { ManagementContainerComponent } from './components/management-container/management-container.component';
import { ManagementNavComponent } from './components/management-container/management-nav.component';
import { UserComponent } from './components/management-container/user.component';
import { AccountsListComponent } from './components/management-container/accounts-list.component';
import { Account } from '../account/models/account';
import { UserSettingsComponent } from './components/management-container/user-settings.component';
import { loggedInGuard } from './logged-in.guard';


const routes: Routes = [
  // execolllect/users access endpoint for list of sites
  // auto redirect to :id of default site
  { path: 'manage', canActivate: [loggedInGuard],
    component: ManagementContainerComponent,// canActivate: [AuthGuard],
    children: [
      {
       path: '', redirectTo: 'user', pathMatch: 'full'
      },
      {
        path: 'user', component: UserComponent,
        children: [
          {
           path: '', redirectTo: 'settings', pathMatch: 'full'
          },
          {
           path: 'settings', component: UserSettingsComponent, 
          },
        ]

      },
      {
       path: 'accounts', component: AccountsListComponent 
      },

    ]
  }
];


@NgModule({
  declarations: [
    ManagementContainerComponent,
    ManagementNavComponent,
    UserComponent,
    AccountsListComponent,
    UserSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RwUtilsModule,
    RouterModule.forChild(routes)
  ],
  exports: [
     RouterModule
  ]

})
export class CurrentUserModule { }
