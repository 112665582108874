import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { Command, Parameter, ParamOpts } from '../../models/command.model';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';

@Component({
  selector: 'app-command-config',
  templateUrl: './command-config.component.html',
  styleUrl: './command-config.component.css'
})
export class CommandConfigComponent {

  @Input() formAction = 'Update';
  @Input() command!: Command;

  @Output() newCommandEvent = new EventEmitter<{ [key: string]: any }>();

  public commandConfigForm!: FormGroup; 
  private currentParams: { [name:string]: Boolean } = {};
   
  conditionalValidator(predicate: Function, validator: Function ) {

    return ( (formControl: FormControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return validator(formControl); 
      }
      return null;
    })
  }

  constructor(
      private http: HttpClient,
      private SiteError: SiteErrorService
  ) {

       const urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';


       this.commandConfigForm = new FormBuilder().group({
                                      name:           [ '', [ Validators.required, ] ], 
                                      command_string: [ 
                                         '', [ this.conditionalValidator(
                                                 () => {
                                                    const type = this.commandConfigForm.get('execute_type')?.value
                                                    
                                                    return type === 'bin' ? true : false
                                                 },
                                                 Validators.required
                                               )
                                             ] 
                                    
                                      ], 
                                      description:    [ '', [ Validators.required, ] ],
                                      execute_type:   [ 'bin', [ Validators.required, ] ],
                                      script_src:     [ '', [ ]],
                                      script_url:     [ '', [ Validators.pattern(urlReg) ]],
                                    });


  }

  ngOnInit(){
     this.buildCmdCfg();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['command']) {
      this.buildCmdCfg();
    }
 
  }

  buildCmdCfg() {
    const command_cfg: { [name:string] : any } = {
       name: this.command.name,
       command_string: this.command.command_string,
       description: this.command.description,
       execute_type: this.command.executeType,
       script_src: this.command.getAttribute('script_src'),
       script_url: this.command.getAttribute('script_url')

    };

    const seenParams: { [name:string]: Boolean } = {}

    this.command.parameters?.forEach( (param)  => {
       //console.log(param)
       seenParams[param.name] = true;

       if ( !this.commandConfigForm?.contains(param.name +'_type') ) {
         this.commandConfigForm?.addControl(param.name +'_type', new FormControl(null, []));

         if ( param.type == 'free_form') {
           this.commandConfigForm?.addControl(param.name +'_regex', new FormControl(null, []));
         }
         else if ( param.type == 'select') {
           this.commandConfigForm?.addControl(param.name +'_newOpt', new FormControl(null, []));
         }
         else if ( param.type == 'range') {
           this.commandConfigForm?.addControl(param.name +'_lower', new FormControl(null, []));
           this.commandConfigForm?.addControl(param.name +'_upper', new FormControl(null, []));
         }

       }

       command_cfg[ param.name +'_type'] = param.type

       if ( param.type == 'free_form') {
         command_cfg[ param.name +'_regex'] = param.opts.regex || '';
       }
       else if ( param.type == 'select') {
         command_cfg[ param.name +'_newOpt'] = ''
       }
       else if ( param.type == 'range') {
         command_cfg[ param.name +'_lower'] =  param.opts.lower || 0;
         command_cfg[ param.name +'_upper'] =  param.opts.upper || 0;
       }

    });

    Object.keys(this.currentParams).forEach(param => {
        if (!seenParams[param]) {
           this.clearParamFields(param);
           this.commandConfigForm?.removeControl(param +'_type');
        }
    });

    this.currentParams = seenParams; 

    this.commandConfigForm.setValue(command_cfg);


  }

  addOpt( param: Parameter ) {
    
    const newOptCtrl = this.cf.get( param.name +'_newOpt');

    const newOpt =  newOptCtrl?.value //  this.cf.value[ param.name +'_newOpt']
    newOptCtrl?.reset();

    if (!newOpt) {
        return
    }
    if ( !param.opts['options'] ) {
       param.opts['options'] = []
    }

    param.opts.options?.push(newOpt)
  }

  removeOpt( param: Parameter, index: number ) {
    param.opts.options?.splice(index, 1);;
  }


  get cf(): FormGroup { return this.commandConfigForm; }

  updateConfig() {

    if (this.cf.invalid) {

      console.log(this.cf.controls);
      
      return;
    }

    const formVals = this.cf.value; 

    const commandConfig = {
         type: 'job',
         name: formVals.name, 
         command_string: formVals.command_string, 
         description: formVals.description, 
         attributes: {
            execute_type: { value: formVals.execute_type },
            script_src:   { value: formVals.script_src },
            script_url:   { value: formVals.script_url },
         },
         parameters: <Array<Object>>[] 
    }

    this.command.parameters?.forEach( (param)  => {
      
         const type = formVals[ param.name +'_type'];

         const opts: ParamOpts = {};
         if ( type == 'free_form') {
           opts.regex = formVals[ param.name +'_regex'];
         }
         else if ( type == 'select') {
           opts.options = param.opts.options //formVals[ param.name +'_options'];
         }
         else if ( type == 'range') {
           opts.lower = formVals[ param.name +'_lower'];
           opts.upper = formVals[ param.name +'_upper'];

         }


         commandConfig.parameters.push({ name: param.name, type: type, opts: opts })    
    });

    //console.log(commandConfig);

    this.newCommandEvent.emit(commandConfig);

  }

  onParamTypeChange(param: string) {
    const newType = this.cf.get(param +'_type')?.value;

    if ( newType == 'free_form' 
         && !this.commandConfigForm?.contains(param +'_regex')
    ) {
      this.clearParamFields(param);
      this.commandConfigForm?.addControl(param +'_regex', new FormControl(null, []));
    }
    else if ( newType  == 'select'
              && !this.commandConfigForm?.contains(param +'_newOpt')
    ) {
      this.clearParamFields(param);
      this.commandConfigForm?.addControl(param +'_newOpt', new FormControl(null, []));
    }
    else if ( newType == 'range'
             && !this.commandConfigForm?.contains(param +'_lower')
    ) {
      this.clearParamFields(param);
      this.commandConfigForm?.addControl(param +'_lower', new FormControl(null, []));
      this.commandConfigForm?.addControl(param +'_upper', new FormControl(null, []));
    }


  }

  clearParamFields(param: string ) {
     this.commandConfigForm?.removeControl(param +'_regex');
     this.commandConfigForm?.removeControl(param +'_lower');
     this.commandConfigForm?.removeControl(param +'_upper');
     this.commandConfigForm?.removeControl(param +'_newOpt');
  }

  validURL() {
    const srcField = this.cf.get('script_url');

    return !srcField?.value || srcField.errors ? false : true; 
  }

  pullScriptSource() {
    const urlField = this.cf.get('script_url');
    const srcField = this.cf.get('script_src');

    this.http.get(urlField?.value, { responseType: 'text' })
             .subscribe({
               next: src => {
                  srcField?.setValue(src);
               },
               error: error => {
                  console.log('error');
                  console.log(error)
                  //this.SiteError.setApiError(error);
               }
             });
  }

}
