import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteErrorService } from '../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { LoadingModalService } from '../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { AccountUsersService } from '../../services/account-users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCollection } from '../../../shared/model/api-coillection.model';
import { UserRole } from '../../models/user-role.model';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.css'
})
export class UsersListComponent {


  public user_roles$!: BehaviorSubject<ApiCollection<UserRole>>

  constructor( 
    private SiteError: SiteErrorService,
    private LoadingModal: LoadingModalService,
    private currentUser: CurrentUserService,
    private UserService: AccountUsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(){

        this.UserService
            .getUserRoles()
            .subscribe( (collection) => { 
               //console.log(collection)
    
              this.user_roles$ = new BehaviorSubject<ApiCollection<UserRole>>(collection);  

               //if ( collection.total == 1 ) {
               //   const user = collection.first();
               //   this.router.navigate([ user.uriKey ], { relativeTo: this.route });
               //}
               
              
            })
  }

  loadUserRoleDetail(user_role: UserRole) {
    this.router.navigate([ user_role.uriKey ], { relativeTo: this.route });
  }

  loadPage( page: number ) {
      let user_roles = this.user_roles$.value;

      let cfg = { searchAttrs: { 
                     order_by: { desc: 'last_checkin' },
                     limit: user_roles.searchAttrs.limit, 
                     page: page 
                  } 
                };
      
        this.UserService
            .getUserRoles(cfg)
            .subscribe( (userCollection) => {
                this.user_roles$.next(userCollection);  
            });
  }

}
