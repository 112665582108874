import { Injectable } from '@angular/core';
import { AccessApiService } from '../../../shared/services/access-api.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { SiteService } from './site.service';
import {sprintf} from "sprintf-js";
import { pipe, skipUntil, filter, of, map } from 'rxjs';
import { ApiCollection } from '../../../shared/model/api-coillection.model';
import { Host, HostStub } from '../models/host.model';
import { Site } from '../models/site.model';
import { HostLog } from '../models/host-log.model';
import { FormControl } from '@angular/forms';
import { ExcClientRequestCfg } from '../../../shared/services/access-api.service';
import { HostUser } from '../models/host-user.model';
import { SiteUser } from '../models/site-user.model';


@Injectable({
  providedIn: 'root'
})
export class HostService {

  currentHost: Host = new HostStub();

  constructor(
    private accessApi: AccessApiService,
    private siteService: SiteService,
  ) { 
    //this.siteService.loaded.subscribe();
  }

  public getHosts( cfg?: ExcClientRequestCfg )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getHostCollectionRequest(site, cfg ) })
                );
  }


  public getCurrentHost( hostUriKey: string )  {
     if ( this.currentHost.uriKey === hostUriKey ) {
        return of(this.currentHost);
     }
     
     return this.getHost(hostUriKey).pipe( tap((host) => { this.currentHost = host }) );
  }


  public getHost( hostUriKey: string )  {
     
     let cfg = { searchArgs: { attributes: { uri_key: hostUriKey } } };

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getHostCollectionRequest(site, cfg) }),
                  map( (collection) => { 
                    const host = collection.first();

                    if ( !host ) {
                      throw new Error(`Host ${ hostUriKey } not found`); 
                    }
 
                    return host 
                  })
                );
  }



  private getHostCollectionRequest( site:  Site, cfg?: ExcClientRequestCfg ) {

      return this.accessApi.getCollection(Host, sprintf('/excollect_api/sites/%s/hosts', site.id), cfg)
  }

  public getHostLogs(host: Host, cfg?: { [name: string]: any } ) {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 

                    let path = sprintf('/excollect_api/sites/%s/hosts/%s/logs', site.id, host.id);

                    return this.accessApi.getCollection(HostLog, path, cfg)
                    //return this.getHostLogsCollectionRequest(site, host) 
                  }),
                  first()
                );
  }

  public updateCurrentHost( host: Host )  {
      return this.updateHost(host)
                 .pipe( tap((host) => { this.currentHost = host }) );

  }

  public addHost( host: Host )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.updateHostCollectionRequest(site, [ host.onCreateData() ]) } ),
                  map( (collection) => { return collection.first() })
                );
  }

  public updateHost( host: Host )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.updateHostCollectionRequest(site, [ host.updateData() ] ) } ),
                  map( (collection) => { return collection.first() })
                );
  }

  public updateHostCollectionRequest( site:  Site, data: Array<Object> ) {
    
      return this.accessApi.updateCollection(Host, sprintf('/excollect_api/sites/%s/hosts', site.id), data )
  }

  public getActivationToken( host: Host ) {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getActivationTokenRequest(site, host) } )
                );
  } 

  private getActivationTokenRequest( site: Site, host: Host ) {

      return this.accessApi.get(
                              sprintf('/excollect_api/sites/%s/hosts/%s/activation_token', site.id, host.id )
                           )
                           .pipe( 
                              first(),
                              switchMap(  resp => {
                                 return of( resp.data.message );  
                              })
                           )

  }

  public getHostUsers( host: Host, cfg?: ExcClientRequestCfg )  {


     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getHostUsersCollectionRequest(site, host, cfg) 
                  }),
                  first()
                );
  }

  private getHostUsersCollectionRequest( site:  Site, host: Host, cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/excollect_api/sites/%s/hosts/%s/users', site.id, host.id );

      return this.accessApi
                 .getCollection(HostUser, path , cfg)
  }

  public addHostUsers( host: Host, newUsers: Array<string>, cfg?: ExcClientRequestCfg )  {

    let data = newUsers.map( (user_id) => {
                  return { user: { id: user_id } } 
               });

    return this.siteService
               .getActiveSite()
               .pipe( 
                 switchMap( (site) => { 

                   const path = sprintf('/excollect_api/sites/%s/hosts/%s/users', 
                                        site.id, host.id );

                   return this.accessApi.updateCollection( SiteUser, path, data, cfg) 
                 }),
                 first()
               );
  }

  public removeHostUsers( host: Host, userIds: Array<string> )  {

    const cfg = {
       searchArgs: { user: { id: { in: userIds  } } }
    }

    return this.siteService
               .getActiveSite()
               .pipe( 
                 switchMap( (site) => { 

                   let path = sprintf('/excollect_api/sites/%s/hosts/%s/users', 
                                       site.id, host.id );

                   return this.accessApi.delete( path, cfg) 
                 }),
                 first()
               );
  }

  public getAvailableUsers( host: Host, cfg?: ExcClientRequestCfg )  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                                                                           
                    let path = sprintf('/excollect_api/sites/%s/hosts/%s/available_users', 
                                        site.id, host.id );

                    return this.accessApi.getCollection( SiteUser, path , cfg)

                  }),
                  first()
                );
  }





  hostnameValidator(control: FormControl): { [key: string]: any } | null {
    const hostname = control.value;
    const hostnameRegex = /^(?!-)[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/;

    if (!hostnameRegex.test(hostname)) {
      return { 'invalidHostname': true };
    }

    return null;
  }

}
