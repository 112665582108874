import { Site } from "./site.model";
import { BaseApiObject } from "../../../shared/model/base-api-object";
import { WithAttributes } from "../mixins/with-attributes.mixin";

import { objectAttributes } from "../../../shared/interfaces/object-attributes"
export class SiteUser extends WithAttributes(BaseApiObject) {

   public status!: string;
   public account_user!: string;
   public site!: Site;
   public groups!: Array<Object>;
   public permissions!: {[ permission: string]: string | number | boolean};

   //public attributes!: objectAttributes;

   constructor(
      private  userData?: SiteUser 
   ) { 
      super(userData);

      delete this.userData;

      this.site = new Site(this.site);
   }

   public override get writable() { 
       return ['status', this.updateAttributes.bind(this) ] 
   };

   public override writableAttributes = ['see_all_users', 'edit_users', 'create_commands', 
                                          'create_clients'];


   get uriKey(): string {
     //return <string>this.attributes['uri_key'].value
     return <string>this.getAttribute('uri_key');
   }

   public hasSitePermission( permission: string ): Boolean {
       if ( !this.permissions ) {
          return false;
       }

       if (this.permissions['is_admin']) {
         return true
       }

       return this.permissions[permission] ? true : false;
   }


   public get isAdmin(): Boolean {
       return this.permissions['is_admin'] ? true : false;
   }

}
