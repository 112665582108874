<div *ngIf="(user$ | async) as user_role" class="px-4" >
  <div class="col">
    <div class="row py-2">
       <div class="col-6">
          <h5 class="mb-0 pt-4">Profile</h5> 
       </div>
       <!--
       <div class="col-6">
          <button type="submit" class="btn btn-primary">Update</button>
       </div>
      -->
    </div>
    <div class="row ps-3">
      <label for="full_name" class="form-label fw-bold col-1">Name</label>
      {{  user_role.user.first_name +' '+  user_role.user.last_name }}

      <!--
      <div class="col-md-5 col-sm-12">
        <input type="text" 
               class="form-control" 
               placeholder="First name" 
               aria-label="First name"
               formControlName="first_name"       
        >
      </div>
      <div class="col-md-5 col-sm-12">
        <input type="text" 
               class="form-control" 
               placeholder="Last name" 
               aria-label="Last name"
               formControlName="last_name"       
        >
      </div>
      -->
    </div>
    <div class="row ps-3">
      <label for="full_name" class="form-label fw-bold col-1">Email</label>
      {{  user_role.user.email_addr }}
      <!--
      <div class="col-md-5 col-sm-12">
        <input type="text" 
               class="form-control" 
               placeholder="Email Address" 
               aria-label="Email Address"
               formControlName="email_addr"       
        >
      </div>
      -->
    </div>
    <form  [formGroup]="userSettingsForm" (ngSubmit)="updateUserSettings()">
      <fieldset [disabled]="!isAdmin || (!isOwner && user_role.role.name == 'Owner')">
        <div class="row py-1">
           <div class="col-5 ">
              <h5 class="mb-0 pt-4 ">Settings</h5> 
           </div>
           <div class="col-7 pt-4">
              <button type="submit" class="btn btn-primary">Update</button>
           </div>
        </div>

        <div class="py-3 ps-3 text-align-end row">
          <label for="status" class="form-label fw-bold col-2">Status</label>
          <div class="col-3">
            <select class="form-select" formControlName="status" id="status" >
                <option *ngIf="user_role.status == 'pending'"  value="pending">
                  Pending Activation 
                </option>
                <option value="enabled">
                  Enabled 
                </option>
                <option value="disabled">
                  Disabled
                </option>
                <option  *ngIf="user_role.user.status == 'closed'" value="closed">
                  Closed 
                </option>
            </select>
          </div>
        </div>

        <div class="py-3 ps-3 row">
          <label for="status" class="form-label fw-bold col-2">Role</label>
          <div class="col-3">
            <select class="form-select" 
                    formControlName="role_name" 
                    id="role_name" 
            >
                <option *ngIf="user_role.role.name == 'Owner'"  value="Owner">
                  Account Owner
                </option>
                <option value="Administrator">
                  Account Administrator
                </option>
                <option  value="Service">
                  Service User
                </option>
            </select>
          </div>
        </div>

      </fieldset>
    </form>

    <div *ngIf="user_role.activation_link" class="py-1 ps-3 row">
      <label for="status" class="form-label fw-bold col-2">Activation Link:</label>
      <div class="col-10">
      <a [href]="user_role.activation_link">Copy Link</a>
      </div>
    </div>

  </div>
</div>

