import { Site } from "./site.model";
import { BaseApiObject } from "../../../shared/model/base-api-object";
import { SiteUser } from "./site-user.model";

export class CommandUser extends BaseApiObject {

    public execute!: number;
    public user!: SiteUser;

    constructor(
       private  userData?: CommandUser 
    ) { 
       super(userData);

       delete this.userData;

       this.user = new SiteUser(this.user);
    }

    public override get writable() { 
       return ['execute'] 
    };



}
