import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountUsersService } from '../../../../account/services/account-users.service';
import { SiteUsersService } from '../../services/site-users.service';
import { SiteService } from '../../services/site.service';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { BehaviorSubject, switchMap, filter, combineLatestWith, forkJoin, of } from 'rxjs';
import { SiteUser } from '../../models/site-user.model';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrl: './user-detail.component.css'
})
export class UserDetailComponent {

  public user$!: BehaviorSubject<SiteUser>;
  public userMap!: { [name: string]: any };


  public userConfigForm: FormGroup  = new FormBuilder().group({
                                              status: ['', [Validators.required ] ],
                                              see_all_users: ['', [Validators.required ] ],
                                              create_clients: ['', [Validators.required ] ],
                                              edit_users: ['', [Validators.required ] ],
                                              create_commands: ['', [Validators.required ] ],
                                            });



  constructor( 
    private AccountUserService: AccountUsersService, 
    private SiteUserService: SiteUsersService, 
    private siteService: SiteService,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService,
    private route: ActivatedRoute,

  ) {}

  ngOnInit(){

    const cfg = { searchAttrs: { 
                     limit: 10, 
                     page:  1 
                  } 
                };


    this.route.params
              .pipe(
                filter( params => params['user_id'] ),
                combineLatestWith( 
                   this.AccountUserService.isLoaded() 
                ),
                switchMap(([ params, loaded ]) => { 
                  return this.SiteUserService.getUser(params['user_id'])  
                }),
                switchMap((user) =>  forkJoin([
                      of(user),
                      of(this.AccountUserService.userMap$.value),
                ])), 

              )
              .subscribe( ([user, userMap]) => {

                this.userConfigForm
                    .setValue({ 
                      status: user.status, 
                      see_all_users: user.isAdmin   || user.hasSitePermission('see_all_users'),
                      create_clients: user.isAdmin  || user.hasSitePermission('create_clients'),
                      edit_users: user.isAdmin      || user.hasSitePermission('edit_users'),
                      create_commands: user.isAdmin || user.hasSitePermission('create_commands'),
                    });

                this.userMap = userMap['user_map'];
                this.user$   = new BehaviorSubject(user); 

                if ( this.userMap[user.account_user].role == 'Owner' ) {
                  this.uf.get('status')?.disable()
                } 

              });


  }

  get uf(): FormGroup { return this.userConfigForm; }

  updateUserConfig(user: SiteUser) {

    if (this.uf.invalid) {

      // TODO generalize into some error reporting service
      const result: any[] = [];
      Object.keys(this.uf.controls).forEach(key => {
    
        const controlErrors = this.uf.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': key,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      });

      return;
    }

    this.LoadingModal.setLoading();

    const formVals    = this.uf.value; 

    user.status = formVals.status;
    user.setAttribute('see_all_users', formVals.see_all_users, true);
    user.setAttribute('edit_users', formVals.edit_users, true);
    user.setAttribute('create_commands', formVals.create_commands, true);
    user.setAttribute('create_clients', formVals.create_clients, true);

    this.SiteUserService
        .updateUser(user)
        .subscribe({
          next: (user) => {
               this.user$.next(user);
               this.LoadingModal.unsetLoading();

          },
          error: (error) => {
              this.LoadingModal.unsetLoading();
              this.SiteError.setApiError(error);
          }
        });

  }

}
