import { Site } from "./site.model";
import { SiteUser } from "./site-user.model";
import { BaseApiObject } from "../../../shared/model/base-api-object";
import { WithAttributes } from "../mixins/with-attributes.mixin";


export class SiteUserGroup extends WithAttributes(BaseApiObject) {

    public name!: string;
    public description!: string;
    public groun_type!: string;
    public site!: Site;
    public members!: Array<SiteUser>;

    constructor(
       private  groupData?: SiteUserGroup 
    ) { 
       super(groupData);

       delete this.groupData;

       this.site    = new Site(this.site);
       this.members = this.members?.map( member => new SiteUser(member) );

    }

    get uriKey(): string {
      return <string>this.getAttribute('uri_key');
    }

    get readOnly(): string {
      return <string>this.getAttribute('read_only');
    }

    get readOnlyMembers(): string  {
      return <string>this.getAttribute('members_read_only');
    }

}
