<div class="d-flex flex-column h-100">
  <div class="row px-4">
    <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th class="col-5 text-center">Name</th>
            <th class="col-4 text-center">Description</th>
            <th class="col-4 text-center">Members</th>
          </tr>   
        </thead>
        <tbody *ngIf="(groups$ | async) as groups; else loadingBlock" >
             <tr *ngFor="let group of groups.collection; index as i" (click)="loadGroupDetail(group)">
               <td class="col-5 text-center">{{ group.name }}</td>
               <td class="col-4 text-center">{{ group.description }}</td>
               <td class="col-4 text-center">{{ group.members.length }}</td>
             </tr>   
        </tbody>
    </table>
  </div>
  <div *ngIf="(groups$ | async) as groups" class="row mt-auto mx-auto">
    <app-pager [collection]="groups" (newPage)="loadPage($event)"></app-pager>
  </div>
  <ng-template #loadingBlock>
    <p>Loading...</p>
  </ng-template>
  <ng-template #waitRegBlock>
     <td>Awaiting Registration</td>
  </ng-template>

</div>



