<div *ngIf="(command$ | async) as command" class="container ps-0 ms-0">
  <div class="row justify-content-center">
    <div class="mt-4">
      <ul class="nav nav-tabs mb-1 ps-2" id="HostDetailTabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active text-dark bg-white" 
                id="system-tab" 
            >
            Add Command  
            </a>
          </li>
      </ul>
    </div>
    <app-command-config 
         [formAction]="formAction" 
         [command]="command"
         (newCommandEvent)="createCommand($event)"
    >
    </app-command-config>

  </div>
</div>

