<div class="container d-flex flex-column ps-0 ms-0 h-100">
  <div class="row">
    <div class="col py-4">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item ps-2">
            <a class="nav-link inactive_tab" 
                id="system-tab" 
                routerLink="list"
                routerLinkActive="active text-dark bg-white"
            >
            Users 
            </a>
          </li>
          <li class="nav-item mx-1">
            <a class="nav-link inactive_tab" 
               id="logs-tab" 
               routerLink="groups"
               routerLinkActive="active text-dark bg-white"
            >
            Groups 
            </a>
          </li>
      </ul>
    </div>
    <!--
    -->
  </div>
  <router-outlet></router-outlet> 

</div>
