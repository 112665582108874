<div class="container d-flex flex-column h-100">
  <div class="row px-4">
    <div class="col-8 ps-4 pt-4">
      <div *ngIf="(hosts$ | async) as hosts">
        <a 
           *ngIf="activeUser.hasSitePermission('create_clients')"
           [routerLink]="['create']">         
           <button class="btn btn-primary">
              <i class="bi bi-plus-square-fill me-1"></i> Add Client 
           </button>
        </a>
      </div>
    </div>
    <div class="col-4  px-4 pt-4">
      <!--
       <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search...">
       -->
    </div>
  </div>
  <div *ngIf="(hosts$ | async) as hosts; else loadingBlock" class="row p-4">
    <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th class="text-center"   scope="col">Client</th>
            <th class="text-center"   scope="col">Status</th>
            <th class="text-center"   scope="col">Last Check-In</th>
          </tr>   
        </thead>
        <tbody  >
             <tr *ngFor="let host of hosts.collection; index as i" (click)="loadHostDetail(host)">
               <td class="text-center">{{ host.hostname }}</td>
               <td class="text-center"*ngIf="host.status!=='wait_reg' else waitRegBlock"  >{{ host.status }}</td>
               <td class="text-center">{{ host.last_checkin_diff }}</td>
             </tr>   
        </tbody>
    </table>
  </div>
  <div *ngIf="(hosts$ | async) as hosts " class="row mt-auto mx-auto">
    <app-pager [collection]="hosts" (newPage)="loadPage($event)"></app-pager>
  </div>
  <ng-template #loadingBlock>
    <app-tab-loading></app-tab-loading>
  </ng-template>
  <ng-template #waitRegBlock>
     <td class="text-center">Awaiting Registration</td>
  </ng-template>

</div>