import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatestWith, first, switchMap } from 'rxjs';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { CommandService } from '../../services/command.service';
import { Command } from '../../models/command.model';
import { AccountUsersService } from '../../../../account/services/account-users.service';
import { SiteService } from '../../services/site.service';
import { SiteUser } from '../../models/site-user.model';

@Component({
  selector: 'app-commands',
  templateUrl: './commands-list.component.html',
  styleUrl: './commands-list.component.css'
})
export class CommandsComponent {

  public commands$!: BehaviorSubject<ApiCollection<Command>>
  public userMap!: { [name: string]: any };
  public userIDMap!: { [name: string]: any };
  public activeUser!: SiteUser;

  constructor( 
    private CommandService: CommandService, 
    private router: Router,
    private route: ActivatedRoute,
    private SiteService: SiteService,
    private AccountUserService: AccountUsersService, 

  ) {}

  ngOnInit(){
      
      const cfg = { 
        searchAttrs: { limit: 15 },
        filter: {
           attributes: {
             script_src: 1
           }
        } 
      };

      this.AccountUserService.isLoaded()
                      .pipe(
                        switchMap( () => this.SiteService.isSiteLoaded() ),
                        combineLatestWith( 
                           this.CommandService.getCommands(cfg),
                           this.AccountUserService.userMap$, 
                           //this.SiteService.activeSiteUser$
                        ),
                        first()
                      ) 
                      .subscribe({
                        next: ([loaded, commandCollection, userMap ]) => {
                          
                          this.userMap    = userMap['user_map'];
                          this.userIDMap  = this.SiteService.userIDMap;
                          this.activeUser = this.SiteService.activeSiteUser$.value;

                          this.commands$ = new BehaviorSubject<ApiCollection<Command>>(commandCollection);  
                        },
                        error: (error) => {
                         console.log(error)
                        }
                      });




      //this.CommandService.getCommands().subscribe( (commandCollection) => {
      //    
      //    this.commands$ = new BehaviorSubject<ApiCollection<Command>>(commandCollection);  
      //});
  }

  loadCommandDetail(command: Command) {
    this.router.navigate([ command.uriKey ], { relativeTo: this.route });
  }

  loadPage( page: number ) {
      let hosts = this.commands$.value;

      let cfg = { searchAttrs: { limit: hosts.searchAttrs.limit, page: page } };

      this.CommandService.getCommands(cfg).subscribe( (hostCollection) => {
          this.commands$.next(hostCollection);  
      });


  }

}
