<div id='exc-side-nav' class="h-100 bg_secondary">

  <ul class="nav align-items-start flex-column h-100 ms-2 pt-2">
    <!--
    <li class="nav-item ">
      <a class="nav-link" 
         routerLink="dashboard"
         routerLinkActive="text-bg-secondary"
      >
      Dashboard
      </a>
    </li>
    -->
    <li class="nav-item ">
      <a class="nav-link" 
         routerLink="hosts"
         routerLinkActive="text-bg-secondary"
      >
      Clients
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
         routerLink="commands"
         routerLinkActive="text-bg-secondary"
      >
      Commands
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
         routerLink="instances"
         routerLinkActive="text-bg-secondary"
      >
      Instances
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
         routerLink="users"
         routerLinkActive="text-bg-secondary"
      >
      Users
      </a>
    </li>
    <!--
    <li class="nav-item mb-4">
      <a class="nav-link" 
         routerLink="settings"
         routerLinkActive="text-bg-secondary"
      >
      Settings
      </a>
    </li>
    -->
  </ul>

</div>
