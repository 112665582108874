import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, switchMap, debounceTime, forkJoin, tap, first, of } from 'rxjs';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { SiteService } from '../../services/site.service';
import { Command } from '../../models/command.model';
import { CommandHost } from '../../models/command-host.model';
import { CommandService } from '../../services/command.service';
import { Host } from '../../models/host.model';
import { SelectedOptions } from '../../../../shared/modules/rw-utils/components/select-from-available/select-from-available.component';

@Component({
  selector: 'app-command-hosts-tab',
  templateUrl: './command-hosts-tab.component.html',
  styleUrl: './command-hosts-tab.component.css'
})
export class CommandHostsTabComponent {

  public command$!: BehaviorSubject<Command>
  public commandHosts$!: BehaviorSubject<ApiCollection<CommandHost>>
  public availableHostsList$!: BehaviorSubject<ApiCollection<Host>>

  //private selectedHosts: SelectedOptions = {};
  //private selectedCommandHosts: SelectedOptions = {};
  //private existingCommandHosts: SelectedOptions = {};
  
  private getHostsCfg = { 
    searchAttrs: { 
       limit: 15, 
       page:  1 
    } 
  };

  constructor( 
    private CommandService: CommandService, 
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
  ) {}

  ngOnInit(){

    this.route.params
              .pipe(
                filter( params => params['command_id'] ),
                switchMap((params) => { 
                  return this.CommandService.getCurrentCommand(params['command_id'])  
                }),
                //tap( command => this.command$ = new BehaviorSubject<Command>(command) ),
                switchMap((command) =>  forkJoin([
                      of(command),
                      this.CommandService.getCommandHosts(command, this.getHostsCfg), //.pipe( first() ),
                      this.CommandService.getCommandAvailableHosts(command, this.getHostsCfg), //.pipe( first() ),
                ])), 
              )
              .subscribe( ([command, commandHosts, hosts]) => {

                this.command$ = new BehaviorSubject<Command>(command) 
                this.commandHosts$ = new BehaviorSubject<ApiCollection<CommandHost>>(commandHosts);  
                //this.hosts = hosts;
                this.availableHostsList$ = new BehaviorSubject<ApiCollection<Host>>(hosts);  

              });

  }

  //isHostSelected( host: Host) {
  //   return this.selectedHosts[host.id]
  //}

  //isCommandHostSelected( commandHost: CommandHost) {
  //   return this.selectedCommandHosts[commandHost.id]
  //}


  //toggleHostSelect(host: Host) {
  //  if ( this.selectedHosts[host.id] ) {
  //      this.selectedHosts[host.id] = false
  //  }
  //  else {
  //      this.selectedHosts[host.id] = true
  //  }

  //}

  //toggleCommandHostSelect(host: CommandHost) {
  //  if ( this.selectedCommandHosts[host.id] ) {
  //      this.selectedCommandHosts[host.id] = false
  //  }
  //  else {
  //      this.selectedCommandHosts[host.id] = true
  //  }

  //}

  //alreadyCommandHost(host:Host) {
  //   return this.existingCommandHosts[host.id]
  //}

  //editCheck(): Boolean {
  //   const command = this.CommandService.currentCommand;//this.command$.value;

  //   return command.hasPermission('edit')

  //}

  doAddCommandHosts(selected: SelectedOptions ) {
  //    this.selectedHosts = selected;
  //    this.doAddCommandHosts();
  //}

  //doAddCommandHosts() {
    let currentCommand = this.CommandService.currentCommand;

    let new_hosts: Array<string> = Object.keys(selected)

    //this.selectedHosts = {};

    if ( new_hosts.length == 0 ) {
        console.log('no hosts selected');
        return
    }

    this.CommandService.addCommandHosts( currentCommand, new_hosts )
                       .pipe(
                           switchMap((commandHosts) =>  forkJoin([
                                 this.CommandService.getCommandHosts(currentCommand, this.getHostsCfg), 
                                 this.CommandService.getCommandAvailableHosts(currentCommand, this.getHostsCfg),
                           ])), 
                       )
                       .subscribe( ([commandHosts, hosts]) => {
                            this.commandHosts$.next(commandHosts);  
                            //this.hosts = hosts;
                            this.availableHostsList$.next(hosts);  


                       })
  }

  doRemoveCommandHosts(selected: SelectedOptions ) {
  //    this.selectedCommandHosts = selected;
  //    this.doRemoveCommandHosts();
  //}


  //doRemoveCommandHosts() {
    let currentCommand = this.CommandService.currentCommand;

    let del_hosts: Array<string> = Object.keys(selected)

    //this.selectedCommandHosts = {};

    if ( del_hosts.length == 0 ) {
        console.log('no command hosts selected');
        return
    }

    this.CommandService.removeCommandHosts( currentCommand, del_hosts )
                       .pipe(
                           switchMap((deletes) =>  forkJoin([
                                 this.CommandService.getCommandHosts(currentCommand, this.getHostsCfg), 
                                 this.CommandService.getCommandAvailableHosts(currentCommand, this.getHostsCfg),
                           ])), 
                       )
                       .subscribe( ([commandHosts, hosts]) => {
                            this.commandHosts$.next(commandHosts);  
                            //this.hosts = hosts;
                            this.availableHostsList$.next(hosts);  

                       })
  }

  loadCommandHostsPage( page: number ) {
      
      const hosts   = this.commandHosts$.value;
      const command = this.command$.value;

      const cfg = { searchAttrs: { 
                       limit: hosts.searchAttrs.limit, 
                       page: page 
                    } 
                  };
     
      this.CommandService.getCommandHosts( command, cfg )
                         .subscribe( (hostsCollection) => {
                             this.commandHosts$.next(hostsCollection);  
                         });
  }

  loadAvailableHostsPage( page: number ) {
      
      const hosts   = this.availableHostsList$.value;
      const command = this.command$.value;

      const cfg = { searchAttrs: { 
                       limit: hosts.searchAttrs.limit, 
                       page: page 
                    } 
                  };
     
      this.CommandService.getCommandAvailableHosts( command, cfg )
                         .subscribe( (hostsCollection) => {
                             this.availableHostsList$.next(hostsCollection);  
                         });
  }

}
