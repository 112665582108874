import { Component } from '@angular/core';
import { Command, CommandStub } from '../../models/command.model';
import { CommandService } from '../../services/command.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SiteService } from '../../services/site.service';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';

@Component({
  selector: 'app-command-create',
  templateUrl: './command-create.component.html',
  styleUrl: './command-create.component.css'
})
export class CommandCreateComponent {

  public formAction: string = 'Create';
  public command$: BehaviorSubject<Command> = new BehaviorSubject<Command>(new CommandStub);  

  constructor(
    private commandService: CommandService,
    private router: Router,
    private route: ActivatedRoute,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService
  ) {
  }


  createCommand( newCommandCfg: Object) {

    const newCommand = new Command(<Command>newCommandCfg);
    
    this.LoadingModal.setLoading();

    this.commandService
        .addCommand(newCommand)
        .subscribe({
          next: (command) => {
            this.LoadingModal.unsetLoading();

            this.router.navigate([ '../'+ command.uriKey ], { relativeTo: this.route });
          },
          error: (error) => {
              this.LoadingModal.unsetLoading();
              this.SiteError.setApiError(error);
          }
        });


  }
}
