import { Component } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject, of, forkJoin, combineLatestWith } from 'rxjs';
import { first, tap, filter, switchMap, subscribeOn } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { UserGroupService } from '../../services/user-group.service';
import { SiteUser } from '../../models/site-user.model';
import { AccountUsersService } from '../../../../account/services/account-users.service';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { HostService } from '../../services/host.service';
import { Host } from '../../models/host.model';
import { HostUser } from '../../models/host-user.model';



@Component({
  selector: 'app-host-users',
  templateUrl: './host-users.component.html',
  styleUrl: './host-users.component.css'
})
export class HostUsersComponent {

  public host$!: BehaviorSubject<Host>; 
  public users$!: BehaviorSubject<ApiCollection<HostUser>>; 
  public available$!: BehaviorSubject<ApiCollection<SiteUser>>;
  public userMap!: { [name: string]: any };

  private selectedAvailable: { [ key: string ]: any } = {};
  private selectedUsers: { [ key: string ]: any } = {};
  private existingUser: { [ key: string ]: any } = {};

  private user_get_cfg = { 
    searchAttrs: { 
       limit: 10, 
       page:  1 
    } 
  };

  constructor( 
    private AccountUserService: AccountUsersService, 
    private UserGroupService: UserGroupService, 
    private route: ActivatedRoute,
    private siteService: SiteService,
    private HostService: HostService,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService
  ) {}

  ngOnInit(){

    const cfg = { searchAttrs: { 
                     limit: 10, 
                     page:  1 
                  } 
                };

    this.route.params
              .pipe(
                filter( params => params['host_id'] ),
                combineLatestWith( 
                   this.AccountUserService.isLoaded() 
                ),
                switchMap(([ params, loaded ]) => { 
                  console.log(loaded);
                  return this.HostService.getCurrentHost(params['host_id'])  
                }),
                switchMap((host) =>  forkJoin([
                      of(host),
                      of(this.AccountUserService.userMap$.value),
                      this.HostService.getHostUsers(host, cfg ),
                      this.HostService.getAvailableUsers(host, cfg ),
                ])), 

              )
              .subscribe( ([host, userMap, users, available ]) => {

                 this.userMap = userMap['user_map'];
                 this.host$   = new BehaviorSubject(host); 
                 this.users$ = new BehaviorSubject(users);
                 this.available$ = new BehaviorSubject(available)

              });
  }

  toggleAvailableSelect(user: SiteUser) {

    if ( this.selectedAvailable[user.id] ) {
        this.selectedAvailable[user.id] = false
    }
    else {
        this.selectedAvailable[user.id] = true
    }
  }

  isAvailableSelected( user: SiteUser) {
     return this.selectedAvailable[user.id]
  }

  toggleUserSelect(user: HostUser) {

    if ( this.selectedUsers[user.user.id] ) {
        this.selectedUsers[user.user.id] = false
    }
    else {
        this.selectedUsers[user.user.id] = true
    }
  }

  isUserSelected( user: HostUser) {
     return this.selectedUsers[user.user.id]
  }

  doAddUser() {
    const host = this.host$.value;

    const newUsers: Array<string> = Object.keys(this.selectedAvailable)

    this.selectedAvailable = {};

    if ( newUsers.length == 0 ) {
        console.log('no hosts selected');
        return
    }

    this.LoadingModal.setLoading();

    this.HostService
        .addHostUsers( host, newUsers )
        .pipe(
            switchMap((commandUsers) =>  forkJoin([
             this.HostService.getHostUsers(host, this.user_get_cfg ),
             this.HostService.getAvailableUsers(host, this.user_get_cfg ),

            ])), 
        )
        .subscribe({ 
          next :([hostUsers, availableUsers]) => {
            this.users$.next(hostUsers);  
            this.available$.next(availableUsers);  

            this.LoadingModal.unsetLoading();

          },
          error: (error) => {
            this.LoadingModal.unsetLoading();
            this.SiteError.setApiError(error);
          }
        })

  }

  doRemoveUser() {
    const host = this.host$.value;

    const removeUsers: Array<string> = Object.keys(this.selectedUsers)

    this.selectedUsers = {};

    if ( removeUsers.length == 0 ) {
        console.log('no hosts selected');
        return
    }

    this.LoadingModal.setLoading();

    this.HostService
        .removeHostUsers( host, removeUsers )
        .pipe(
            switchMap(() =>  forkJoin([
             this.HostService.getHostUsers(host, this.user_get_cfg ),
             this.HostService.getAvailableUsers(host, this.user_get_cfg ),

            ])), 
        )
        .subscribe({ 
          next: ([commandUsers, availableUsers]) => {
            this.users$.next(commandUsers);  
            this.available$.next(availableUsers);  

            this.LoadingModal.unsetLoading();
          },  
          error: (error) => {
            this.LoadingModal.unsetLoading();
            this.SiteError.setApiError(error);
          }
        })

  }

  loadAvailablePage( page: number ) {
      
      const available = this.available$.value;
      const host      = this.host$.value;

      const cfg = { searchAttrs: { 
                       limit: available.searchAttrs.limit, 
                       page: page 
                    } 
                  };
     
      this.HostService.getAvailableUsers( host, cfg )
                         .subscribe( (userCollection) => {
                             this.available$.next(userCollection);  
                         });
  }

  loadUsersPage( page: number ) {
      
      const users = this.users$.value;
      const host  = this.host$.value;

      const cfg = { searchAttrs: { 
                       limit: users.searchAttrs.limit, 
                       page: page 
                    } 
                  };
     
      this.HostService.getHostUsers( host, cfg )
                      .subscribe( (usersCollection) => {
                          this.users$.next(usersCollection);  
                      });
  }



}
