import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './navbar/navbar.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AccountModule } from './account/account.module';
import { ExcollectModule } from './service/excollect/excollect.module';
import { LoggedInDropDownComponent } from './navbar/logged-in-drop-down.component';
import { GuestDropDownComponent } from './navbar/guest-drop-down.component';
import { RwUtilsModule } from './shared/modules/rw-utils/rw-utils.module';
import { AccountActivateComponent } from './register/account-activate.component';
import { ServiceSetupComponent } from './register/service-setup.component';
import { FooterComponent } from './footer/footer.component';
import { CurrentUserModule } from './current-user/current-user.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { credentialsInterceptor } from './credentials.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FrontPageComponent,
    LoginComponent,
    RegisterComponent,
    LoggedInDropDownComponent,
    GuestDropDownComponent,
    AccountActivateComponent,
    ServiceSetupComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    //NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RwUtilsModule,
    CurrentUserModule,
    AccountModule,
    ExcollectModule,
    AppRoutingModule, // always last
  ],
//  exports: [
//    ErrorComponent
//  ],
  providers: [
    provideHttpClient()
    //provideHttpClient(withInterceptors([credentialsInterceptor]))
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
