import { Component } from '@angular/core';
import { CurrentUserService } from '../shared/services/current-user.service';


@Component({
  selector: 'app-logged-in-drop-down',
  template: `
    <div class="dropdown">
      <i class="bi bi-person-circle dropdown-toggle me-3" data-bs-toggle="dropdown"  style="font-size: 2rem; color: white;"></i>
      <ul class="dropdown-menu dropdown-menu-end">
        <li><a class="dropdown-item" routerLink="/account">Account Settings</a></li>
        <li><a class="dropdown-item" routerLink="/manage">User Settings</a></li>
        <div class="dropdown-divider"></div>
        <li><a class="dropdown-item" (click)="do_logout()">Log Out</a></li> 
      </ul>
    </div>
  `,
  styles: `
    .dropdown > ul > li > a:hover{
        background-color:#616060;
        color: white 
    } 
  `
})
export class LoggedInDropDownComponent {

  constructor( private currentUser: CurrentUserService ) {

  }

  public do_logout(): void {
      this.currentUser.logout();
  }

}
