import { ValidatorFn, AbstractControl, FormGroup  } from '@angular/forms';

export function matchValidator(
                   controlName: string, 
                   matchingControlName: string,
                   errorMsg: string = 'Fields do not match'
                ): ValidatorFn {

  return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);

      if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
          return null;
      }

      if (control!.value !== matchingControl!.value) {
        const error = { confirmedValidator: errorMsg };
        matchingControl!.setErrors(error);
        return error;
      } else {
        matchingControl!.setErrors(null);
        return null;
      }
  }
}

export function getFormErrors( f: FormGroup ): Array<{}>|undefined{

    if (f.invalid) {

      // TODO generalize into some error reporting service
      const result: any[] = [];
      Object.keys(f.controls).forEach(key => {
    
        const controlErrors = f.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': key,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      });

      return result;
    }

    return undefined
}

export function getFormControlError( f: FormGroup, key: string, submitted: Boolean ): Array<{error: string, value: string}>|undefined{

    const control = f.get(key);

    if ( !(control?.invalid && ( submitted || control?.dirty || control?.touched ) ) ) {
      return undefined; 
    }

    const controlErrors = control?.errors;

    const result: Array<{ error: string, value: string}> = []; 

    if ( controlErrors ) {
          
          Object.keys(controlErrors).forEach(keyError => {
            let err_msg: string;
            if ( keyError == 'required' ) { 
               err_msg = 'This field is required'
            }
            else if (keyError == 'pattern') {
               err_msg = 'Value must match: '+ controlErrors[keyError].requiredPattern

            }
            else if (keyError == 'min') {
               err_msg = 'Value must be at least '+ controlErrors[keyError].min
            }
            else if (keyError == 'max') {
               err_msg = 'Value cannot exceed '+ controlErrors[keyError].max
            }
            else {
              err_msg = 'Error'
            }

            result.push({
              'error': keyError,
              'value': err_msg 
            });
          });

      return result;
    }

    return undefined
}