import { Component } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject, of, forkJoin, combineLatestWith } from 'rxjs';
import { first, tap, filter, switchMap, subscribeOn } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { UserGroupService } from '../../services/user-group.service';
import { SiteUser } from '../../models/site-user.model';
import { AccountUsersService } from '../../../../account/services/account-users.service';
import { CommandService } from '../../services/command.service';
import { Command } from '../../models/command.model';
import { CommandUser } from '../../models/command-user.model';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';


@Component({
  selector: 'app-command-users-tab',
  templateUrl: './command-users-tab.component.html',
  styleUrl: './command-users-tab.component.css'
})
export class CommandUsersTabComponent {

  public command$!: BehaviorSubject<Command>; 
  public users$!: BehaviorSubject<ApiCollection<CommandUser>>; 
  public available$!: BehaviorSubject<ApiCollection<SiteUser>>;
  public userMap!: { [name: string]: any };

  private selectedAvailable: { [ key: string ]: any } = {};
  private selectedUsers: { [ key: string ]: any } = {};
  private existingUser: { [ key: string ]: any } = {};

  private user_get_cfg = { 
    searchAttrs: { 
       limit: 10, 
       page:  1 
    } 
  };

  constructor( 
    private AccountUserService: AccountUsersService, 
    private UserGroupService: UserGroupService, 
    private route: ActivatedRoute,
    private siteService: SiteService,
    private CommandService: CommandService,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService
  ) {}

  ngOnInit(){

    const cfg = { searchAttrs: { 
                     limit: 10, 
                     page:  1 
                  } 
                };

    this.route.params
              .pipe(
                filter( params => params['command_id'] ),
                combineLatestWith( 
                   this.AccountUserService.isLoaded() 
                ),
                switchMap(([ params, loaded ]) => { 
                  console.log(loaded);
                  return this.CommandService.getCurrentCommand(params['command_id'])  
                }),
                switchMap((command) =>  forkJoin([
                      of(command),
                      of(this.AccountUserService.userMap$.value),
                      this.CommandService.getCommandUsers(command, cfg ),
                      this.CommandService.getAvailableUsers(command, cfg ),
                ])), 

              )
              .subscribe( ([command, userMap, users, available]) => {

                 //this.groupConfigForm.setValue({ 
                 //                        name:         group.name, 
                 //                        description:  group.description,
                 //                      });

                 this.userMap = userMap['user_map'];
                 this.command$   = new BehaviorSubject(command); 
                 this.users$ = new BehaviorSubject(users);
                 this.available$ = new BehaviorSubject(available)

              });
  }


  toggleAvailableSelect(user: SiteUser) {

    if ( this.selectedAvailable[user.id] ) {
        this.selectedAvailable[user.id] = false
    }
    else {
        this.selectedAvailable[user.id] = true
    }
  }

  isAvailableSelected( user: SiteUser) {
     return this.selectedAvailable[user.id]
  }

  toggleUserSelect(user: CommandUser) {

    if ( this.selectedUsers[user.user.id] ) {
        this.selectedUsers[user.user.id] = false
    }
    else {
        this.selectedUsers[user.user.id] = true
    }
  }

  isUserSelected( user: CommandUser) {
     return this.selectedUsers[user.user.id]
  }

  doAddUser() {
    const group = this.command$.value;

    const newUsers: Array<string> = Object.keys(this.selectedAvailable)

    this.selectedAvailable = {};

    if ( newUsers.length == 0 ) {
        console.log('no hosts selected');
        return
    }

    this.LoadingModal.setLoading();

    this.CommandService
        .addCommandUsers( group, newUsers )
        .pipe(
            switchMap((commandUsers) =>  forkJoin([
             this.CommandService.getCommandUsers(group, this.user_get_cfg ),
             this.CommandService.getAvailableUsers(group, this.user_get_cfg ),

            ])), 
        )
        .subscribe({ 
          next :([commandUsers, availableUsers]) => {
            this.users$.next(commandUsers);  
            this.available$.next(availableUsers);  

            this.LoadingModal.unsetLoading();

          },
          error: (error) => {
            this.LoadingModal.unsetLoading();
            this.SiteError.setApiError(error);


          }

        })

  }

  doRemoveUser() {
    const group = this.command$.value;

    const removeUsers: Array<string> = Object.keys(this.selectedUsers)

    this.selectedUsers = {};

    if ( removeUsers.length == 0 ) {
        console.log('no hosts selected');
        return
    }

    this.LoadingModal.setLoading();

    this.CommandService
        .removeCommandUsers( group, removeUsers )
        .pipe(
            switchMap((commandUsers) =>  forkJoin([
             this.CommandService.getCommandUsers(group, this.user_get_cfg ),
             this.CommandService.getAvailableUsers(group, this.user_get_cfg ),

            ])), 
        )
        .subscribe({ 
          next: ([commandUsers, availableUsers]) => {
            this.users$.next(commandUsers);  
            this.available$.next(availableUsers);  

            this.LoadingModal.unsetLoading();
          },  
          error: (error) => {
            this.LoadingModal.unsetLoading();
            this.SiteError.setApiError(error);


          }

        })

  }

  loadAvailablePage( page: number ) {
      
      const available = this.available$.value;
      const command   = this.command$.value;

      const cfg = { searchAttrs: { 
                       limit: available.searchAttrs.limit, 
                       page: page 
                    } 
                  };
     
      this.CommandService.getAvailableUsers( command, cfg )
                         .subscribe( (userCollection) => {
                             this.available$.next(userCollection);  
                         });
  }

  loadUsersPage( page: number ) {
      
      const users   = this.users$.value;
      const command = this.command$.value;

      const cfg = { searchAttrs: { 
                       limit: users.searchAttrs.limit, 
                       page: page 
                    } 
                  };
     
      this.CommandService.getCommandUsers( command, cfg )
                         .subscribe( (usersCollection) => {
                             this.users$.next(usersCollection);  
                         });
  }

  toggleUserExecute(user: CommandUser, event: Event ) {
     
     const command = this.command$.value;

     user.execute ^= 1; 

     this.CommandService.updateCommandUser( command, user )
                        .subscribe({
                           next: () => {

                           },
                           error: (error) => {

                           }
                        });
  }

}
