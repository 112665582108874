<div class="px-3">
  <form  [formGroup]="commandConfigForm" (ngSubmit)="updateConfig()">
    <fieldset [disabled]="command.id && !command.hasPermission('edit')">
      <div class="row mt-4 mb-4">
         <div class="col-8">
           <h5 class="mb-0 mt-2">Settings</h5>
         </div>
         <div class="col-4">
            <button type="submit" class="btn btn-primary">{{ formAction }}</button>
         </div>
      </div>
      <div class="col-12 ms-3 pt-4" > 
        <div class="mb-3 row">
          <label for="name" class="form-label fw-bold col-2">Name</label>
          <div class="col-4">
              <input type="text" 
                   class="form-control" 
                   id="name" 
                   aria-describedby="name" 
                   formControlName="name">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="description" class="form-label fw-bold col-2">Description</label>
          <div class="col-4">
              <input type="text" 
                   class="form-control" 
                   id="description" 
                   aria-describedby="description" 
                   formControlName="description">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="execute_type" class="form-label fw-bold col-2">Type</label>
          <div class="col-4">
            <select class="form-select" formControlName="execute_type" id="execute_type" >
                <option value="bin">
                  Command
                </option>
                <option value="script">
                  Script 
                </option>
            </select>
          </div>
        </div>
        <div *ngIf="cf.get('execute_type')?.value == 'bin'">
          <div  class="mb-3 row">
            <label for="command" class="form-label fw-bold col-2">Command</label>
            <div class="col-7">
                <input type="text" 
                     class="form-control" 
                     id="command" 
                     aria-describedby="command" 
                     formControlName="command_string">
            </div>
          </div>
          <ng-container
            [ngTemplateOutlet]="paramFields"
            [ngTemplateOutletContext]="{ $implicit: command }"
          >
          </ng-container>
          <!--
          <ng-content select="[command]"></ng-content>
          <div *ngIf="command.has_parameters()" command>
            <div class="mb-3 row">
              <label for="name" class="form-label fw-bold col-6">Parameters </label>
              <div class="row">
                <div class="col-2 d-flex justify-content-center">
                  <label class="fw-bold">Name</label>
                </div>
                <div class="col-2  d-flex justify-content-center">
                  <label class="ps-3 fw-bold">Type</label>
                </div>
                <div class="col-5  d-flex justify-content-center">
                  <label class="ps-3 fw-bold">Constraints</label>
                </div>

              </div>
              <div *ngFor="let param of command.parameters"  class="row ps-4 py-2" >
                <div class="col-2 d-flex align-items-center">
                    <label class="ps-3 fw-bold col-2">{{ param.name }}</label>
                </div>
                <div class="col-2">
                  <div class="row">
                    <select class="form-select" 
                            formControlName="{{ param.name +'_type' }}" 
                            id="param.name +'_type'" 
                            (change)="onParamTypeChange( param.name )"
                    >
                      <option value="free_form">
                        Free 
                      </option>
                      <option value="select">
                        Options 
                      </option>
                      <option value="range">
                        Range
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-8">
                  <div *ngIf="cf.get(param.name +'_type')?.value == 'free_form'" class="row">

                    <label class="align-self-center ps-3 me-0 fw-bold col-2">Match</label>
                    <div class="col-8 ps-0">
                        <input type="text" 
                             class="form-control" 
                             id="{{ param.name +'_regex' }}" 
                             aria-describedby="param.name +'_regex'" 
                             formControlName="{{ param.name +'_regex' }}">
                    </div>
                  </div>
                  <div *ngIf="cf.get(param.name +'_type')?.value == 'select'" class="row">

                    <div class=" d-inline-block">
                      <div *ngFor="let opt of param.opts.options; ; index as i" 
                           class="btn btn-secondary  py-1 px-2 mx-2 my-1"
                      >
                           {{ opt }}
                           <i class="bi bi-file-x-fill"
                              (click)="removeOpt(param, i)" 
                           >
                           </i>
                      </div>

                      <div class="btn btn-secondary btn-sm px-2 ms-2">
                         <input id="{{ param.name +'_new_opt' }}" 
                                type="text" 
                                size="8"
                                formControlName="{{ param.name +'_newOpt' }}"
                         > 

                         <i class="ps-2 bi bi-plus-square-fill h5"
                            (click)="addOpt(param)" 
                         ></i>
                      </div> 
                    </div>
                  </div>
                  <div *ngIf="cf.get(param.name +'_type')?.value == 'range'" class="row">

                    <label class="align-self-center ps-3 me-0 fw-bold col-2">Lower</label>
                    <div class="col-3">
                       <input type="number" 
                              class="form-control text-end" 
                              id="{{ param.name +'_lower' }}" 
                              min="-9999"
                              max="9999"
                              aria-describedby="lower" 
                              formControlName="{{ param.name +'_lower' }}">
                    </div>
                    <label class="align-self-center ps-3 me-0 fw-bold col-2">Upper</label>
                    <div class="col-3">
                      <input type="number" 
                             class="form-control text-end" 
                             id="{{ param.name +'_upper' }}" 
                             min="-9999"
                             max="9999"
                             aria-describedby="upper" 
                             formControlName="{{ param.name +'_upper' }}">

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          -->
        </div>
        <div *ngIf="cf.get('execute_type')?.value == 'script'">
          <div  class="mb-3 row">
            <label for="script_url" class="form-label fw-bold col-2">Source</label>
            <div class="col-7">
                <input type="text" 
                     class="form-control" 
                     id="script_url" 
                     aria-describedby="script_url" 
                     formControlName="script_url">
            </div>
            <div class="col-2">
               <button 
                  [class.disabled]="!validURL()"
                  addDeBounce
                  type='button' 
                  class="btn btn-primary" 
                  (deBounceClick)="pullScriptSource()"
                >
                  Pull
                </button>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="script_src" class="form-label fw-bold col-2">Arguments</label>
            <div class="col-8">
                <input type="text" 
                     class="form-control" 
                     id="command" 
                     aria-describedby="command" 
                     formControlName="command_string">
            </div>
          </div>
          <ng-container
            [ngTemplateOutlet]="paramFields"
            [ngTemplateOutletContext]="{ $implicit: command }"
          >
          </ng-container>
          <!--
          <ng-content select="[script]" ></ng-content>
          -->
          <div class="mb-3 row">
            <label for="script_src" class="form-label fw-bold col-12">Script</label>
            <div class="col-10">
              <textarea 
                     rows="25"
                     class="form-control" 
                     id="script_src" 
                     formControlName="script_src"> 
              </textarea>
            </div>
          </div>
  
        </div>
  
      </div>
    </fieldset> 
  </form>
</div>

<ng-template #paramFields let-command>
  <div *ngIf="command.has_parameters()" [formGroup]="commandConfigForm">
    <div class="mb-3 row">
      <label for="name" class="form-label fw-bold col-6">Parameters </label>
      <!--
      <h5>Parameters</h5>
      -->
      <div class="row">
        <div class="col-2 d-flex justify-content-center">
          <label class="fw-bold">Name</label>
        </div>
        <div class="col-2  d-flex justify-content-center">
          <label class="ps-3 fw-bold">Type</label>
        </div>
        <div class="col-5  d-flex justify-content-center">
          <label class="ps-3 fw-bold">Constraints</label>
        </div>

      </div>
      <div *ngFor="let param of command.parameters"  class="row ps-4 py-2" >
        <div class="col-2 d-flex align-items-center">
            <label class="ps-3 fw-bold col-2">{{ param.name }}</label>
        </div>
        <div class="col-2">
          <div class="row">
            <select class="form-select" 
                    formControlName="{{ param.name +'_type' }}" 
                    id="param.name +'_type'" 
                    (change)="onParamTypeChange( param.name )"
            >
              <option value="free_form">
                Free 
              </option>
              <option value="select">
                Options 
              </option>
              <option value="range">
                Range
              </option>
            </select>
          </div>
        </div>
        <div class="col-8">
          <div *ngIf="cf.get(param.name +'_type')?.value == 'free_form'" class="row">

            <label class="align-self-center ps-3 me-0 fw-bold col-sm-3 col-md-2">Match</label>
            <div class="col-7 ps-0">
                <input type="text" 
                     class="form-control" 
                     id="{{ param.name +'_regex' }}" 
                     aria-describedby="param.name +'_regex'" 
                     formControlName="{{ param.name +'_regex' }}">
            </div>
          </div>
          <div *ngIf="cf.get(param.name +'_type')?.value == 'select'" class="row">

            <div class=" d-inline-block">
              <div *ngFor="let opt of param.opts.options; ; index as i" 
                   class="btn btn-secondary  py-1 px-2 mx-2 my-1"
              >
                   {{ opt }}
                   <i class="bi bi-file-x-fill"
                      (click)="removeOpt(param, i)" 
                   >
                   </i>
              </div>

              <div class="btn btn-secondary btn-sm px-2 ms-2">
                 <input id="{{ param.name +'_newOpt' }}" 
                        type="text" 
                        size="8"
                        formControlName="{{ param.name +'_newOpt' }}"
                 > 

                 <i class="ps-2 bi bi-plus-square-fill h5"
                    (click)="addOpt(param)" 
                 ></i>
              </div> 
            </div>
          </div>
          <div *ngIf="cf.get(param.name +'_type')?.value == 'range'" class="row">

            <label class="align-self-center ps-3 me-0 fw-bold col-sm-2 col-md-1">Min</label>
            <div class="col-3">
               <input type="number" 
                      class="form-control text-end" 
                      id="{{ param.name +'_lower' }}" 
                      min="-9999"
                      max="9999"
                      aria-describedby="lower" 
                      formControlName="{{ param.name +'_lower' }}">
            </div>
            <label class="align-self-center ps-3 me-0 fw-bold col-sm-2 col-md-1">Max</label>
            <div class="col-3">
              <input type="number" 
                     class="form-control text-end" 
                     id="{{ param.name +'_upper' }}" 
                     min="-9999"
                     max="9999"
                     aria-describedby="upper" 
                     formControlName="{{ param.name +'_upper' }}">

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>
