import { HttpStatusCode } from "axios";
import { BaseApiObject } from "../../../shared/model/base-api-object";
import { WithAttributes } from "../mixins/with-attributes.mixin";
import { Command } from "./command.model";

import { localizeDateTime } from "../../../utils/model-utils";

export class CommandInstance extends WithAttributes(BaseApiObject) {

    public is_stub: boolean = false;

    public command!: Command;
    public label!: string;
    public status!: string;
    public command_string!: string;
    public created_datetime!: string;
    public start_datetime!: string;
    public complete_datetime!: string;
    //public user_permissions!: {[ permission: string]: string | number | boolean};

    constructor(
       private instanceData?: CommandInstance 
    ) { 
       super(instanceData);

       delete this.instanceData;

       this.command  = new Command(this.command);
    }

    public override get onCreate() { 
       return [] 
    };

    public override get writable() { 
       return [ 'label' , this.updateAttributes.bind(this) ] 
    };

    public override writableAttributes = ['command_priority', 'max_retry', 
                                          'retry_delay', 'max_queuing'];

    get uriKey() {
      return <string>this.getAttribute('uri_key');
    }

    get executeType() {
      return <string>this.getAttribute('execute_type');
    }

    get scriptSrc() {
      return <string>this.getAttribute('script_src');
    }


    get localCreatedDateTime() {
      return localizeDateTime(this.created_datetime);
    }

    get localStartDateTime() {
      return localizeDateTime(this.start_datetime);
    }

    get localCompleteDateTime() {
      return localizeDateTime(this.start_datetime);
    }


}
export class CommandInstanceStub extends CommandInstance { 

   public override is_stub: boolean = true;

   constructor(
   ) {
     super( <CommandInstance>{} );

     this.label   = '';
     this.status  = '';
     this.attributes   = { 
       uri_key:  { 
          name: 'uri_key',
          type: 'string',
          description: '',
          value:      '',
          group:  'CONFIG'
       } 
    }
   }
}
