<div *ngIf="(command$ | async) as command" class="container h-100 d-flex flex-column ">
  <app-select-from-available
     [groupCollection$]="commandHosts$" 
     [availableCollection$]="availableHostsList$"
     [canEdit]="command.hasPermission('edit')"
     (addToGroup)="doAddCommandHosts($event)"
     (removeFromGroup)="doRemoveCommandHosts($event)"
     (newGroupPage)="loadCommandHostsPage($event)"
     (newAvailablePage)="loadAvailableHostsPage($event)"
     
  >

    <h4 members-header>Command Clients</h4>

    <tr members-table-header>
      <th class="col-7 text-center">Client</th>
      <th class="col-2 text-center">Status</th>
      <th class="col-3 text-center">Priority</th>
    </tr>

    <ng-template #memberRowT let-member>
      <td class="col-7 text-center">{{ member.host.hostname }}</td>
      <td class="col-2 text-center">{{ member.host.status }}</td>
      <td class="col-3 text-center">{{ member.priority }}</td>
    </ng-template>

    <h4 available-header>Available Clients</h4>

    <tr available-table-header>
      <th class="col-6 text-center">Client</th>
    </tr>

    <ng-template #availableRowT let-available>
      <td class="text-center">{{ available.hostname }}</td>
    </ng-template>


  </app-select-from-available>

</div>