import { BaseApiObject } from "../../shared/model/base-api-object";
import { ApiCollection } from "../../shared/model/api-coillection.model";
import { WithAttributes } from "../../service/excollect/mixins/with-attributes.mixin";
import { AccountRole } from "./account-role.model";
import { localizeDateTime } from "../../utils/model-utils";
export class AccountUser extends WithAttributes(BaseApiObject) {

    public first_name!: string;
    public last_name!: string;
    public email_addr!: string;
    public created!: string;
    public updated!: string;
    public last_login!: string;
    public status!: string;
    public accounts_roles!: Array<AccountRole>

    constructor(
      // stuff is marked optional here so CurrentUser can init stub objects for 
      // CurrentUserService, probably a better way to do this.
      private userData?: AccountUser 
    ) { 
       super(userData);

       delete this.userData;

       this.accounts_roles = this.accounts_roles?.map( accountRole => new AccountRole(accountRole) );
    }

    public override get writable() { 
       return ['first_name','last_name','email_addr', this.updateAttributes.bind(this) ] 
    };
    //public override writableAttributes = ['uri_key'];

    public get name() { 
        return this.first_name +' '+ this.last_name;
    }

    public override deserialize(input: any): this {
        super.deserialize(input)

        this.accounts_roles = this.accounts_roles?.map( accountRole => new AccountRole(accountRole) );

        return this;
    }

    get localLastLogin() {
       return localizeDateTime(this.last_login);
    }
}
