import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteErrorComponent } from './modals/site-error/site-error.component';
import { LoadingComponent } from './modals/loading/loading.component';
import { AddDeBounceClickDirective } from './directives/add-de-bounce-click.directive';
import { PagerComponent } from './components/pager/pager.component';
import { TabLoadingComponent } from './components/tab-loading/tab-loading.component';
import { CallbackFilterPipe } from './pipes/callback-filter.pipe';
import { SelectFromAvailableComponent } from './components/select-from-available/select-from-available.component';


@NgModule({
  declarations: [
    SiteErrorComponent,
    LoadingComponent,
    AddDeBounceClickDirective,
    PagerComponent,
    TabLoadingComponent,
    CallbackFilterPipe,
    SelectFromAvailableComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SiteErrorComponent,
    LoadingComponent,
    AddDeBounceClickDirective,
    PagerComponent,
    TabLoadingComponent,
    CallbackFilterPipe,
    SelectFromAvailableComponent
  ]

})
export class RwUtilsModule { }
