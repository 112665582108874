import { BaseApiObject } from "../../../shared/model/base-api-object";
import { WithAttributes } from "../mixins/with-attributes.mixin";
import { SiteUser } from "./site-user.model";

export class HostUser extends WithAttributes(BaseApiObject) {

    public user!: SiteUser;

    constructor(
       private  userData: SiteUser 
    ) { 
       super(userData);

       this.user = new SiteUser(this.user);
    }

}
