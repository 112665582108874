"use strict";

import utils from './utils.js'
import axios from 'axios'; 
import jsrsasign from 'jsrsasign'
import axiosRetry from 'axios-retry';

function ExCollectClient(opts) {

  this.key_id      = opts['key_id'];
  this.private_key = opts['private_key'];
  this.api_url     = opts['api_url'];

  this.logger     = utils.getLogger();
  this.signer     = utils.getSigner();
  this.user_agent = this._build_user_agent(); 

  this.needs_time_offset_update = true;

}

ExCollectClient.prototype._build_user_agent = function () {


  const axios_instance = axios.create({
    "baseURL": this.api_url,
    "timeout": 3000,
    "paramsSerializer": function(params) {
       let result = new URLSearchParams(params).toString()
                                               .replace(/%3A/gi, ':')
                                               .replace(/,/gi, '%2C');

       return result;
    }
  });

  axiosRetry(axios_instance, { retries: 0 });


  axios_instance.defaults.headers = { 'Content-Type' : 'application/json' };

  axios_instance.interceptors.request.use(
    function ( config ) {

      //console.log(config);
      if ( 'data' in config ) {
         // console.log('got data');
         config['data'] =  ( config['method'].toLowerCase() != 'get' ) 
                             ? JSON.stringify( config['data'], null ) 
                             : '';
      }

      if ( config['no_sig'] ) { 
          delete config['no_sig'];

          return config;
      }

      if ( this.private_key !== undefined ) {

        const sign_opts = {
          'key_id'      : this.key_id,
          'time_offset' : this.time_offset,
          'priv_key'    : this.private_key
        };  

        this.signer.sign_api_request( config, sign_opts )
      }

      return config; 
    }.bind(this),
    function  ( error ) { 
      Promise.reject(error) 
    }
  );  

  axios_instance.interceptors.response.use(
    function (response) {

      return response;
    },
    function ( error ) {

      //this.logger.info(error);

      return Promise.reject(error);
    }.bind(this)
  );

  return axios_instance;
};


ExCollectClient.prototype.request = async function ( req_cfg ) {

  //this.logger.debug('request', req_cfg );
  await this.get_time_offset();
  try {

    const resp = await this.user_agent.request(req_cfg);

    this.logger.debug('Client Success', resp); 

    return resp;
  }
  catch (error) {

    if ( error.response ) {

      this.logger.error( error.response.status, error.response.data);
    } 
    else if (error.request) {
      // The request was made but no response was received 
      this.logger.error('No Response', error.request);

    } 
    else {
      // Something happened in setting up the request that triggered an Error
      this.logger.error('Critical Error', error.message);
    } 

    return Promise.reject(error);
  }

}; 

ExCollectClient.prototype.get = async function ( url, params ) {

    params ||= {};
    params['url']    = url;
    params['method'] = 'get';

    return await this.request(params); 
};

ExCollectClient.prototype.post = async function ( url, data, params ) {

    params ||= {};
    params['url']    = url; 
    params['data']   = data; 
    params['method'] = 'post';

    return await this.request(params); 
}; 

ExCollectClient.prototype.put = async function ( url, data, params ) {

    params ||= {}; 
    params['url']    = url;
    params['data']   = data;
    params['method'] = 'put';

    return await this.request(params); 
};


ExCollectClient.prototype.patch = async function ( url, data, params ) {

    params ||= {}; 
    params['url']    = url;
    params['data']   = data;
    params['method'] = 'patch';

    return await this.request(params); 
}; 

ExCollectClient.prototype.delete = async function ( url, params ) {

    params ||= {}; 
    params['url']    = url;
    params['method'] = 'delete';

    return await this.request(params); 
}; 


ExCollectClient.prototype.get_time_offset = async function () { 

    if ( this.needs_time_offset_update === false ) {
        return this.time_offset;
    }

    const ts = utils.getCurrentTimeStamp();

    try {
       const resp = await this.user_agent
                              .request({ 
                                 no_sig: 1, 
                                 method: 'post',  
                                 url:    '/time_offset', 
                                 data:   { timestamp: ts } 
                               });
       //console.log('RESP', resp);

       this.time_offset = resp.data['message']['offset'];
       this.needs_time_offset_update = false;
    }
    catch ( error ) {
       this.logger.error('Error updating timeoffset: '+ error);
 
       // Fallback to just setting it to zero
       // With needs_time_offset_update still set to 
       // true the next request will retry setting.
       // the real value.
       this.time_offset = 0;

       Promise.reject(error);  
    }

    return this.time_offset;
};


ExCollectClient.prototype.login = async function ( email, password ) { 

    const KEYUTIL = jsrsasign.KEYUTIL;
    const login_data = {
       email:    email,
       password: password
    };

    try {
       const resp = await this.user_agent
                              .request({
                                 no_sig: 1,
                                 method: 'post',
                                 url:    '/account_api/users/login_user',
                                 data:   login_data
                               });


       const priv_key_locked = resp.data['message']['data']['api_key']['private_key_locked']; 
       const priv_key        = KEYUTIL.getKey( priv_key_locked, password );  

       this.key_id      = resp.data['message']['data']['id'];  
       this.private_key = KEYUTIL.getPEM( priv_key, "PKCS1PRV"); 

       return resp;
    }
    catch ( error ) {
       this.logger.error(error);

       return Promise.reject(error);  
    }
}; 


ExCollectClient.prototype.signCommandInstance = function ( instance ) {

   if ( !this.logged_in() ) {
      throw new Error('Not logged in');
   }

   return this.signer.sign_command_instance( instance, this.key_id, this.private_key );  

   //const tokens = {
   //  instance_id:    instance['id'],
   //  command_id:     instance['command'], 
   //  site_id:        instance['site'],
   //  site_user_id:   instance['signed_by'], 
   //  created_ts:     instance['created_datetime'],
   //  key_id:         this.key_id,
   //  command_string: instance['command_string'],  
   //}

   //return this.signer.sign_command_instance( tokens, this.private_key ); 
}

ExCollectClient.prototype.log_out = function () {  
   delete this.key_id; 
   delete this.private_key;
} 

ExCollectClient.prototype.logged_in = function () {  
   return (this.key_id && this.private_key) ? true : false;
}

export default ExCollectClient; 


