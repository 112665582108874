<div class="container ps-0 ms-0 h-100">
                                                 <!-- fixes row breaking out of div-->
  <div class="row ps-0 justify-content-center"  style="margin: auto">

    <div class="mt-4 ps-0">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item px-2">
            <a class="nav-link active text-dark bg-white" 
                id="setting-tab" 
            >
            Settings 
            </a>
          </li>
      </ul>
    </div>

    <div *ngIf="(user$ | async) as user; else loadingBlock" class="px-3" >
      <div class="col">
        <form  [formGroup]="userConfigForm" (ngSubmit)="updateUserConfig(user)">
          <fieldset [disabled]="!user.hasPermission('edit')">
            <div class="row">
               <div class="col-6">
                 <h5 class="mb-0 mt-2">Info</h5> 
               </div>
               <div class="col-6" *ngIf="user.hasPermission('edit')">
                  <button type="submit" class="btn btn-primary">Update</button>
               </div>
            </div>
            <div class="col-10 px-3 py-4" > 
              <div class="mb-3 row">
                <label for="name" class="form-label fw-bold col-2">Email</label>
                <div class="col-5">
                  {{ userMap[user.account_user].email_addr }}
                </div>
              </div>
              <div class="mb-3 row">
                <label for="name" class="form-label fw-bold col-2">Admin</label>
                <div class="col-5">
                  {{ user.isAdmin ? 'Yes' : 'No' }}
                </div>
              </div>
              <div class="mb-3 row">
                <label for="description" class="form-label fw-bold col-2">Status</label>
                <div class="col-5 col-lg-3">
                  <select class="form-select" 
                          formControlName="status" id="status" >
                    <option value="active">
                      Active
                    </option>
                    <option value="disabled">
                      Disabled
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset [disabled]="user.isAdmin || !user.hasPermission('edit')">

            <div class="row">
               <div class="col-6">
                 <h5 class="mb-0 mt-2">Permissions</h5> 
               </div>
               <div class="col-6">
                  
               </div>
            </div>
            <div class="col-10 px-3 py-4" > 
              <div class="mb-3 row">
                <label for="name" class="form-label fw-bold col-3">See All users</label>
                <div class="col-5">
                  
                  <div class="form-check form-switch ms-3">
                      <input class="form-check-input mx-auto" 
                             formControlName="see_all_users" 
                             style="transform: scale(1.3);"
                             type="checkbox" 
                             role="switch" 
                             id="see_all_users" 
                             [checked]="user.hasPermission('see_all_users')">
                  </div>

                </div>
              </div>
              <div class="mb-3 row">
                <label for="name" class="form-label fw-bold col-3">Edit Users</label>
                <div class="col-5">

                  <div class="form-check form-switch ms-3">
                      <input class="form-check-input mx-auto" 
                             formControlName="edit_users" 
                             style="transform: scale(1.3);"
                             type="checkbox" 
                             role="switch" 
                             id="toggleExecute" 
                             [checked]="user.hasPermission('edit_users')">
                  </div>

                </div>
              </div>

              <div class="mb-3 row">
                <label for="name" class="form-label fw-bold col-3">Create Commands</label>
                <div class="col-5">

                  <div class="form-check form-switch ms-3">
                      <input class="form-check-input mx-auto" 
                             formControlName="create_commands" 
                             style="transform: scale(1.3);"
                             type="checkbox" 
                             role="switch" 
                             id="toggleExecute" 
                             [checked]="user.hasPermission('create_commands')">
                  </div>
                  
                </div>
              </div>
              <div class="mb-3 row">
                <label for="name" class="form-label fw-bold col-3">Add Clients</label>
                <div class="col-5">

                  <div class="form-check form-switch ms-3">
                      <input class="form-check-input mx-auto" 
                             formControlName="create_clients" 
                             style="transform: scale(1.3);"
                             type="checkbox" 
                             role="switch" 
                             id="toggleExecute" 
                             [checked]="user.hasPermission('create_clients')">
                  </div>
                  
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>


  </div>
</div>
<ng-template #loadingBlock>
  <div class="col h-100">
    <app-tab-loading></app-tab-loading>
  </div>
</ng-template>
