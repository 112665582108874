import { Component } from '@angular/core';
import { BehaviorSubject, switchMap, forkJoin, of, combineLatestWith } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { SiteService } from '../../services/site.service';
import { SiteUser } from '../../models/site-user.model';
import { AccountUsersService } from '../../../../account/services/account-users.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.css'
})
export class UsersComponent {

  public users$!: BehaviorSubject<ApiCollection<SiteUser>>
  public userMap!: { [name: string]: any };

  constructor( 
    private SiteService: SiteService, 
    private AccountUserService: AccountUsersService, 
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(){
      
      let cfg = { searchAttrs: { limit: 15 } }; //, order_by: { asc: 'last_checkin' } } };

      //this.SiteService.getUsers(cfg)
      this.AccountUserService.isLoaded()
                      .pipe(
                        //switchMap((loaded) =>  forkJoin([

                        //      of(loaded),
                        //      this.SiteService.getUsers(cfg),
                        //      //this.AccountUserService.userMap$,
                        //])), 
                        combineLatestWith( 
                           this.SiteService.getUsers(cfg), 
                           this.AccountUserService.userMap$ 
                        )
                      ) 
                      .subscribe({

                        //next: ([loaded, userCollection, userMap]) => {
                        next: ([loaded, userCollection, userMap]) => {

                          //console.log('here');
                          //console.log(loaded);
                          this.userMap = userMap['user_map'];
                          //console.log(this.userMap);
                          this.users$ = new BehaviorSubject<ApiCollection<SiteUser>>(userCollection);  
                        },
                        error: (error) => {
                         console.log(error)
                        }
                      });

  }

  loadPage( page: number ) {
      let users = this.users$.value;

      let cfg = { searchAttrs: { 
                     order_by: { desc: 'last_checkin' },
                     limit: users.searchAttrs.limit, 
                     page: page 
                  } 
                };
      
      this.SiteService.getUsers(cfg).subscribe( (userCollection) => {
          this.users$.next(userCollection);  
      });


  }
  
  loadUserDetail(user: SiteUser): void {
    this.router.navigate([ '../'+ user.uriKey ], { relativeTo: this.route });
  } 

  // Handles case where user was activated but isn't in our 
  // current sessionsg userMap. Can cause an empty user page
  // to show up until browser is refreshed.
  filterActiveAccount( userMap: {[name: string]: any} )  {
    return ( user: SiteUser) => {
        return userMap && userMap[user.account_user] ? true : false;
    }
  }

}
